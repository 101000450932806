$customFontFamily: "IBM Plex Sans", sans-serif;

:root {
  /* Font family */
  font-family: $customFontFamily !important;
  --font-family: $customFontFamily !important;

  /* Font feature settings */
  font-feature-settings: normal;
  --font-feature-settings: "cv02", "cv03", "cv04", "cv11";

  /* Font variation settings */
  font-variation-settings: normal;

  --primary-color: #5156be;
}

@import "/node_modules/primereact/resources/themes/lara-light-indigo/theme.css";

html {
  background-color: #f8f9fa;
}

.main-content {
  background-color: #f8f9fa !important;
}

.p-datatable-thead th {
  background-color: #e9e9ef;
}

/* VIEW OVERLAB STYLE */
.subview b {
  display: block;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
}

.p-component {
  font-size: 14px !important;
}

.p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td {
  padding: 4px 10px;
  white-space: nowrap;
}

@media only screen and (max-width:598px) {

  .p-datatable .p-datatable-thead>tr>th:nth-child(-n+2),
  .p-datatable .p-datatable-tbody>tr>td:nth-child(-n+2) {
    width: auto !important;
    left: auto !important;
  }
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
  background: #f5f5f5;
  border-bottom: 1px solid #e8e8f7 !important;
  border-top: 1px solid #e8e8f7 !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight,
.p-datatable .p-datatable-tbody>tr.p-highlight {
  background: #f1f1f1 !important;
  color: #4b5563 !important;
}

.p-checkbox {
  align-items: center;
  justify-content: center;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: unset !important;
}

.p-datatable .p-datatable-tbody>tr {
  color: black !important;
  // background: #f5f5f5;
}

label {
  display: block;
  color: #292827 !important;
}

.p-radiobutton-box {
  border: 1px solid #222;
}

.p-checkbox-box {
  border: 1px solid #222;
}

.req::after {
  content: " *";
  color: tomato;
}

.p-calendar {
  display: flex;
}

.border-leftrigh-none {
  border: none !important;
}

.jobsheet-tab .p-accordion-content {
  padding-left: 0px;
  padding-right: 0px;
}

.p-datepicker-trigger {
  float: right;
}

.p-dropdown {
  display: flex !important;
  background-color: #f8f9fa !important;
}

.p-dropdown-items-wrapper {
  .p-dropdown-items {
    padding: 0 !important;
    margin: 0;
  }
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0;
  margin: 0;
}

.underline-content {
  height: "6px";
  width: "150px";
  background: "#4549a2";
  border-radius: "5px";
  margin: "0 auto";
}

.p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
  border-radius: 4px !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #5156be;
  border-color: #5156be;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 12px !important;
  height: 12px !important;
}

.btn-primary {
  background: #5156be !important;
  color: white !important;
  border: 1px solid #e9e9ef !important;
  box-shadow: 0 2px 6px 0 rgba(233, 233, 239, 0.5) !important;
  // background: linear-gradient(0deg, #8d0026, #e52f2f) !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.active,
.btn-primary.show {
  // background: #5156BE !important;
  color: white !important;
  // background: linear-gradient(45deg, #5156BE, #5156BE) !important
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #f1f1f1 !important;
  border-color: #f1f1f1 !important;
  color: black;
}

.bg-danger {
  background: #5156be !important;
}

.p-inputtext {
  padding: 8px !important;
}

.primary {
  background: #e9e9ef;
  color: #000;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: black;
  background-color: #f1f1f1;
}

.p-datatable-header {
  .p-multiselect {
    width: 300px;
    display: flex;
    margin-left: auto;
  }
}

.p-button.p-button-icon-only {
  padding: 0.5rem 0 !important;
}

.p-button {
  color: #fff !important;
  // color: #818181 !important;
  // background: #ffffff !important;
  background: #604eb8 !important;
  border: 1px solid #d5d5d5 !important;
  border-radius: 5px;
}

button[aria-label="Apply"] {
  color: #fff !important;
  font-family: "IBM Plex Sans", sans-serif !important;
  font-weight: 100 !important;
  background: #604eb8 !important;
  border: 1px solid #d5d5d5 !important;
  border-radius: 5px;
  padding: 5px 10px !important;
}

button[aria-label="Clear"] {
  color: black !important;
  font-weight: 100;
  font-family: "IBM Plex Sans", sans-serif;
  border: 1px solid #cbc9c9;
  background: linear-gradient(180deg, #0000001c, transparent) !important;
  border-radius: 5px;
  padding: 5px 10px;
}

.calendar_button {
  padding: 5px 15px !important;
}

.calendar_button:hover {
  padding: 5px 15px !important;
}

button[aria-label="Clear"]:hover {
  color: black !important;
  font-weight: 100 !important;
  font-family: "IBM Plex Sans", sans-serif !important;
  border: 1px solid #cbc9c9 !important;
  background: linear-gradient(90deg, #0000001c, transparent) !important;
  border-radius: 5px;
  padding: 5px 10px;
}

// .p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover{
//   border-right: 2px solid #5156BE !important;
// }
.p-button-label {
  font-weight: 400 !important;
}

.customize-table-button {
  border: none !important;
  background: transparent !important;
  color: #292827 !important;
  box-shadow: none !important;
  font-weight: bolder !important;
}

.btn.customize-table-button:hover {
  //   border: 1px solid #f9fafb !important;
  //   border-color: #324f6b33 !important;
  //   background: #f9fafb !important;
}

.option-btn-container {
  font-weight: bolder !important;
  background-color: #324f6b !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.p-datatable-header {
  background-color: #f8f9fa !important;
}

.secondary-btn {
  color: black !important;
  border: 1px solid #cbc9c9 !important;
  background: linear-gradient(0deg, #0000001c, transparent) !important;
}

.btn.secondary-btn:hover,
.btn.secondary-btn:active,
.btn.secondary-btn:focus,
.btn.secondary-btn.active,
.btn.secondary-btn.show {
  border-color: #cbc9c9 !important;
  transition: none !important;
}

.secondary-btn:hover {
  color: black !important;
  border: 1px solid #cbc9c9 !important;
  background: linear-gradient(0deg, #0000001c, transparent) !important;
}

.table-header-container {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;

  @media (flex-wrap: wrap) {
    // margin-top: 10px;
    /* Adjust the margin value as needed */
    justify-content: start;
  }

  @media screen and (max-width: 375px) {
    justify-content: left;
    align-items: center;
  }

  .p-dropdown {
    width: 200px;
  }

  .table-header-container-item-special {
    border-left: 1px solid black;
    margin-left: 10px;
  }

  .delete-space {
    margin-left: 10px;
  }

  .table-header-container-item {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;

    .p-dropdown {
      width: 200px;
    }

    @media screen and (max-width: 375px) {
      justify-content: start;
      align-items: center;
      // margin-top: 10px;
      // gap: 10px;
    }

    &:nth-child(n + 3):not(:first-child) {
      // margin-top: 10px;
      /* Adjust the margin value as needed */
      justify-content: start;
    }
  }
}

#stock-music-system {
  tbody td {
    height: 40px;
    text-transform: capitalize;
  }
}

#stock-music-system {
  .p-datatable .p-datatable-thead>tr>th:first-child {
    // position: sticky;
    left: 0;
  }
}

/* Chips */
.p-chips {
  width: 100%;

  .p-inputtext {
    width: 100%;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.table-cell-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.numberDisplay {
  margin-bottom: 20px;
  margin-left: -10px !important;
  background-color: #5156be;
  max-height: 20px !important;
  padding: 8px;
  border: 2px solid white;
  max-width: 20px !important;
  border-radius: 50%;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  opacity: 100;
}

.icon-style-csv-pdf {
  font-size: 30px;
  margin-right: 15px;
  cursor: pointer;
}

.pdf-icon {
  color: #ff1111;
}

.csv-icon {
  color: #00853c;
}

/* body[data-sidebar="light"] .vertical-menu ,.navbar-brand-box {
  background-color: #5156BE!important;
}
 */
.p-datatable-tbody tr {
  border: 1px solid #e9e7f6;
}

.p-datatable-emptymessage {
  border: none !important;
}

.p-datatable-header {
  border-top: none;
  padding-top: 0px;
  padding-left: 0px;
}

/* label{
  white-space: nowrap !important;
} */
.form-control {
  background-color: #f8f9fa !important;
}

.form-control:disabled {
  background-color: #e9e9e9 !important;
}

.form-control:focus {
  color: var(--bs-body-color) !important;
  background-color: var(--bs-tertiary-bg) !important;
  border: 2px solid rgb(81, 86, 190) !important;
  outline: rgba(39, 45, 158, 0.7) !important;
  box-shadow: none !important;
}

.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color) !important;
  padding-right: calc(1.5em + 0.94rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.235rem) center !important;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem) !important;
}

#email {
  .p-inputwrapper-focus {
    outline: none !important;
    border: none !important;
  }

  .is-invalid-editor {
    border-color: var(--bs-form-invalid-border-color) !important;
  }

  .is-invalid {
    ul {
      border-color: var(--bs-form-invalid-border-color) !important;
      background-repeat: no-repeat !important;
      background-position: right calc(0.375em + 0.235rem) center !important;
      background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem) !important;
      max-height: 200px;
      overflow-y: auto;
      // li{
      //   padding: 4px 7px !important;
      // }
      // .p-chips-token{
      //   font-size: 14px !important;
      // }
    }
  }

  .chip-email {
    ul {
      max-height: 200px;
      overflow-y: auto;
      // li{
      //   padding: 4px 7px !important;
      // }
      // .p-chips-token{
      //   font-size: 14px !important;
      // }
    }
  }
}

.form-select {
  background-color: #ffffff !important;
}

@media screen and (max-width: 400px) {
  .custom-row {
    justify-content: center !important;
  }
}

td {
  max-width: 300px !important;
  text-overflow: ellipsis !important;
  overflow-x: hidden !important;
}

.general-details {
  padding: 10px;
  color: #374151 !important;
  background: #f0f0f0 !important;
  font-weight: 600 !important;
  width: 100%;
  float: none;
  font-size: 14px;
}

.view {
  border: 1px solid #e5e7eb !important;
  padding: 0 !important;
  margin-bottom: 10px;
}

.subview {
  padding: 20px 20px !important;
  background-color: #fff !important;
}

.none-text {
  margin-left: 5px !important;
}

.multiadd-container {
  display: flex;
  justify-content: end;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

#tooltip {
  white-space: nowrap;
  position: absolute;
  background-color: #32506bda;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 99;
}

.arrow {
  position: absolute;
}

@media screen and (min-width: 1000px) {
  .custom_select {
    margin-top: 0px;
  }
}

.align-right {
  text-align: right;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.mt-1 {
  margin-top: 10px;
}

.pm-0 {
  padding: 0px !important;
  margin: 0px !important;
}

.align-right {
  text-align: right;
}

.mt-1 {
  margin-top: 10px;
}

.pm-0 {
  padding: 0px !important;
  margin: 0px !important;
}

.custom-label {
  margin-bottom: -2px;
  text-align: center;
  // background-color: #b2d5f3;
  background-color: #5156be;

  b {
    color: white !important;
  }

  padding: 5px;
}

.customgridmain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  .customgridmain .grid-item {
    width: 100%;
  }
}

.p-accordion-header-link {
  color: black;
}

input:read-only {
  cursor: no-drop;
}

.custom-card-parent {
  display: flex;
  justify-content: space-around;
}

.custom-card {
  min-height: 120px;
  max-height: 120px !important;
  margin-bottom: 0px !important;
  border: 1px solid #ccc !important;
  gap: 10px;
  overflow-y: auto;
  padding: 5px;
  scrollbar-width: thin;
  scrollbar-color: rgba(211, 211, 211, 0.5) transparent;
  scroll-behavior: smooth;
}

.remove-index {
  display: flex !important;
  justify-content: end !important;
}

.custom-chechbox {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 20px !important;
}

.custom-checkbox-label {
  // margin-left: 5px !important;
  // margin-top: 5px !important;
  margin: 7px 0 5px 5px !important;
}

.MelError {
  color: red !important;
  font-size: 12px !important;
  margin-top: 5px !important;
}

.delete-text {
  font-size: 2em !important;
  color: orange !important;
}

.input-style {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-top: 36px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.margin-left-3 {
  margin-left: 30px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.validation-text {
  color: red !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.vw30 {
  width: 30vw !important;
}

.height900 {
  height: 900px !important;
}

.height230 {
  height: 230px !important;
}

.bg-primary {
  background-color: #5156be !important;
}

.text-primary {
  color: #5156be !important;
}

.translate3d {
  transform: translate3d(100%, 0px, 0px) !important;
}

.sec-btn {
  background-color: #e9e9ef !important;
  color: black !important;
}

.custom-card-width {
  width: 24rem !important;
}

.custom-date-container {
  margin-bottom: 1rem !important;
}

.padding-none {
  padding: 0 !important;
}

.radio-input {
  margin-right: 7px !important;
}

.radio-input-label {
  margin-left: 5px !important;
}

.full-width {
  height: 100vh !important;
  width: 100% !important;
}

.resend {
  font-size: 20px !important;
}

.logo-width {
  width: 200px !important;
}

.logo-mini-width {
  width: 60px !important;
  margin-left: -5px;
}

.navbar-brand {
  padding: 0 0.5rem !important;
}

.vertical-menu-container-absolute {
  background: #ffffff;
  position: absolute;
}

.vertical-menu-container-fixed {
  background: #ffffff;
  position: fixed;
}

.max-height {
  max-height: 100% !important;
}

.full-height {
  height: 100% !important;
}

.tooltip-color {
  background-color: #324f6b !important;
}

.feathet-icon {
  margin-right: 10px !important;
}

.chart-height {
  height: 350px !important;
}

.alert-margin {
  margin-top: 13px !important;
}

.clear-both {
  clear: both !important;
}

.chat-heigh {
  height: 530px !important;
}

.chat-heigh-max {
  height: 600px !important;
}

.trade-input {
  width: 90px !important;
}

.simple-bar {
  width: 352px !important;
}

.simple-bar-td {
  width: 50px !important;
}

.notification-height {
  min-height: 230px !important;
}

.notification-height-min {
  min-height: 200px !important;
}

.form-checkbox-checked {
  color: #fd625e;
  font-size: 12.25px;
  margin-top: 5px;
}

.custom-radio {
  display: flex;
  gap: 20px;
}

.table-width-min {
  width: 70px !important;
}

.table-width {
  width: 120px !important;
}

.view-none-text {
  margin-left: 30px !important;
}

.width100 {
  width: 100px !important;
}

.borderradius-10 {
  border-radius: 10px !important;
}

.remove-tooltip-margin {
  margin-left: 10px !important;
}

.parts-inbound-width {
  width: 230px !important;
}

.view-table-td {
  padding: 10px 0 !important;
}

.custom-card-height {
  min-height: 120px !important;
  max-height: 240px !important;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(211, 211, 211, 0.5) transparent;
  scroll-behavior: smooth;
  margin-bottom: 0px !important;
}

.grid-item-width {
  min-width: 170px !important;
}

#barcode {
  svg {
    max-width: 200px !important;
    max-height: 70px;
  }
}

.big-font {
  font-size: x-large;
}

.pointer {
  cursor: pointer;
}

.jus-start {
  justify-content: flex-start !important;
}

.remove-icon {
  font-size: larger !important;
  margin-bottom: 10px !important;
}

.mrl-auto {
  margin-left: auto;
}

.dis-flex-btw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intent-status {
  background: white;
  padding: 0px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: small !important;
}

.grn-color {
  color: green;
}

.red-color {
  color: red;
}

.captialize {
  text-transform: capitalize;
}

.width-100 {
  width: 100px;
}

.width-100-per {
  width: 100%;
}

.width-80-per {
  width: 80%;
}

#table-border {

  .p-datatable .p-datatable-thead>tr>th,
  .p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #b8b8b8;
  }
}

#table-border {

  .table-container .p-datatable .p-datatable-thead>tr>th,
  .p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #b8b8b8;
  }
}

.info-msg {
  font-size: small;
  color: blue;
}

.view-image {
  img {
    width: 150px !important;
    height: 150px !important;
    border-radius: 5px;
  }
}

.view-image-whatsapp {
  img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 5px;
  }
}

.custom-card-input {
  // background-color: #fcfcfc;
  background-color: #f8f9fa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px !important;
.get-detail{
  background-color: #5156BE;
  height: 100%;
  color:white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 0px 5px ;
  cursor: pointer;
  // width: 30px;
}
  .scanned {
    min-width: 600px;
    padding: 7px;
  }

  .inp-icon {
    position: relative;

    .left-icon {
      position: absolute;
      bottom: 10px;
      left: 6px;
      color: #5156be;
    }

    .right-icon {
      position: absolute;
      bottom: 10px;
      right: 6px;
      color: #5156be;
    }
  }
}

.custom-scan-input {
  // background-color: #fcfcfc;
  background-color: #f8f9fa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px !important;

  .inp-icon {
    position: relative;
    width: 100%;

    .left-icon {
      position: absolute;
      bottom: 10px;
      left: 6px;
      color: #5156be;
    }

    .right-icon {
      position: absolute;
      bottom: 10px;
      right: 6px;
      color: #5156be;
    }
  }
}

.icon-input {
  display: block;
  width: 100%;
  padding: 5px 30px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
}

.scanned {
  margin: 0px 10px;
  width: 100%;
  display: flex;

  b {
    padding: 0px 5px;
    border-right: 2px solid black;
  }

  .scanned-sku {
    color: green;
    margin: 0 5px;
    font-size: 16px;
  }
}

.display-value {
  // margin-top: 10px;
}

.normal-list {
  list-style-type: none;
  // padding: 5px;
  margin: 3px 0;

  // background-color: #f0f0f0;
  svg {
    margin: 6px;
    color: grey;
  }
}

.tick-list {
  list-style-type: none;
  color: green;
  // padding: 5px;
  margin: 3px 0;

  // background-color: #c3e6cb;
  svg {
    margin: 6px;
    color: green;
  }
}

.new-tick {
  list-style-type: none;
  color: orange;
  // padding: 5px;
  margin: 3px 0;

  // background-color: #c3e6cb;
  svg {
    margin: 6px;
    color: orange;
  }
}

.p-3-custom {
  padding: 0.8rem !important;
}

.custom-card-val {
  display: flex;
  justify-content: space-around;

  div {
    span {
      text-align: center;
      font-weight: 600;
      margin-left: 8px;
    }
  }
}

.custom-sku-card {
  background-color: #f1f1f1;
  width: 100%;
  padding: 15px 5px;

  .header {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
}

.grid-item {
  min-width: 15%;
}

.bg-yellow {
  background-color: #fff491;
  color: black;
}

.part-sku-box {
  max-width: 100%;
  max-height: 200px;
}

.custom-card-sku {
  max-height: 125px;
  min-height: 125px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(211, 211, 211, 0.5) transparent;
  scroll-behavior: smooth;
  border: 1px solid rgba(211, 211, 211, 0.5);
}

.max-width-1 {
  max-width: 100%;
}

@media screen and(min-width:1000px) {
  .max-width-1 {
    max-width: 15% !important;
  }
}

.part-sku-value {
  margin: 3px 5px !important;
  font-weight: 600;
}

.custom-badge {
  width: auto;
  padding: 2px 3px;
  margin: 0px 3px;
  background-color: grey;
  color: #fff;
  border-radius: 5px;
}

.scannerContainer {
  position: relative;
}

.resumeBtn {
  position: absolute;
  display: grid;
  place-items: center;
}

#html5-qrcode-button-camera-stop,
#html5-qrcode-button-camera-permission,
#html5-qrcode-button-camera-start {
  padding: 7.52px 0.75rem;
  border: 1px;
  background-color: #5156be;
  color: #ffffff;
  border-radius: 0.25rem;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
}

#html5-qrcode-anchor-scan-type-change {
  font-family: "IBM Plex Sans", sans-serif;
}

// #reader__dashboard {
//   display: none;
// }

[data-pc-section="sort"] {
  // margin-left: auto;
  margin-left: unset;
}

.p-column-filter-menu {
  margin-left: 0 !important;
}

.label-noWrap {
  white-space: nowrap;
}

@media screen and (max-width: 568px) {
  .p-dialog {
    margin: 10px;
  }
}

.p-dialog-header {
  padding: 1rem !important;
}

#pr_id_1_content {
  padding-bottom: 10px;
}

.calendar-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  z-index: 100;
  right: 7px;
  top: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.p-progress-spinner-svg {
  z-index: 100 !important;
}

.loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* Adjust the z-index as needed */
}

.margin-bottom-0 {
  margin-bottom: 4px !important;
}

.radio-container {
  display: flex;
  align-items: center;
}

.margin-right-10 {
  margin-right: 10px;
}

.p-radiobutton .p-radiobutton-box {
  width: 15px;
  height: 15px;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  height: 5px;
  width: 5px;
}

@media screen and (max-width: 1695px) {
  button.minimize-button {
    font-size: 14px !important;
    white-space: nowrap;
    padding: 5px 5px !important;
    margin-left: 0px !important;
  }
}


@media screen and (max-width: 567px) {
  button.minimize-button {
    font-size: 12px !important;
  }
}

.width150 {
  width: 150px;
}

.fit-content {
  min-width: fit-content !important;
}

.color-yello {
  background: yellow !important;
}

.icon-input-jobsheet {
  position: relative;

  svg {
    width: 20px;
    position: absolute;
    right: 20px;
    top: 8px;
    background-color: #f7f7f7;
  }
}

.color-blue {
  background-color: #d7d9ffec;
  padding: 3px 3px;
  margin: 0px 5px;
  border: 2px solid #9790ff;
  border-radius: 5px;
}

.color-green {
  background-color: #d7ffdc;
  padding: 3px 3px;
  margin: 0px 5px;
  border: 2px solid #58ff66;
  border-radius: 5px;
}

.color-red {
  color: red;
}

.p-datepicker table td {
  padding: 0px !important;
}

.p-datepicker .p-component .p-ripple-disabled .p-connected-overlay-enter-done {
  width: 370px !important;
}

.p-datepicker-month .p-link {
  padding: 0px 10px !important;
}

.p-datepicker-header {
  padding: 0px !important;
}

.p-datepicker-year .p-link {
  padding: 0px 5px !important;
}

.dis-flex {
  display: flex;
}

#technician-drp {
  .p-float-label {
    width: 90% !important;
  }
}

.mr-lf-4 {
  margin-left: 4px;
}

.drop-grp-brd {
  border: 1px solid #d1d5db;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
}

#signature {
  .sign-border {
    border: 2px solid #cbc9c9;
    border-style: dashed;
  }

  .sign-width {
    width: 100%;
  }
}

#signature {
  .sign-border {
    border: 2px solid #cbc9c9;
    border-style: dashed;
  }

  .sign-width {
    width: 100%;
  }
}

#dashboard {
  .img-container {
    height: 100px;
    color: #00000045;
    border: 1px solid;
    padding: 10px;
  }

  .delaer-color {
    background: aliceblue;
  }

  .supplier-color {
    background: beige;
  }

  .franchise-color {
    background: #87ceeb40;
  }

  .customer-color {
    background: #deb887bd;
  }

  .jobsheet-count {
    font-weight: 900 !important;
    color: white !important;
  }

  .jobsheet-subtle {
    background-color: #604eb8 !important;
  }

  .apexcharts-yaxis text,
  .apexcharts-xaxis text {
    fill: #000000 !important;
    font-weight: 800 !important;
  }

  .width-600 {
    max-width: 600px;

    div {
      max-height: 400px !important;
    }
  }
}

#invoice_gp_container {
  display: flex;

  #invoice_group {
    position: relative;
  }

  #invoice_icon {
    text-align: center;
    display: flex;
    position: absolute;
    left: 150px;
    top: 35px;
  }
}

.camera_disabled {
  pointer-events: none;
  opacity: 0.5;
}

.icon-lg:hover {
  cursor: pointer;
}

#Aadhar_details_preview {
  margin: 5px 0;
  border: 1px solid #c6c6cc;
  border-radius: 4px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  .file_name {
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
  }

  .trash_icon {
    padding: 3px;
    margin-right: 10px;
  }
}

#Dashboardbrteadcrumb {
  .p-datepicker {
    width: 300px;
  }
}

#email {
  .custom-email-card-width {
    margin: 0 10px !important;
  }
}

// @media screen and (max-width : 1279px) {
//   .custom_toggle_button{
//     display : block !important;
//   }
// }

// @media screen and (min-width : 1280px)  {
//   .custom_toggle_button{
//     display : none !important;
//   }
// }

#remain_menu {
  position: absolute;
  right: 0px;
  top: 43px;
  width: 190px;
  padding: 0;
  background: aliceblue;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.rewmain_menu_li {
  padding: 10px 10px;

  svg {
    margin-right: 7px;
    color: #000000;
  }
}

.rewmain_menu_li:hover {
  color: black;
  background-color: #cbcdff;
}

#remain_menu1 {
  .rewmain_menu_li {
    padding: 0.75rem 1.1rem;

    svg {
      color: #000000;
    }
  }
}

.remain_dynamica_menu {
  position: absolute;
  top: 55px;
  background: aliceblue;
  left: 500px;
  width: 50%;
  display: grid;
  grid-template-columns: 50% 50%;
}

#bulkEmail {
  ul {
    margin-bottom: 0px !important;
    padding-left: 5px;
  }
}

#bulkEmail .p-tabview-ink-bar {
  left: 5px;
}

@media screen and (max-width: 1300px) {
  .permission_tabview ul {
    width: max-content !important;
  }
}

.ml-05 {
  margin-left: 3px;
}

.view-bulk-mail-icon {
  width: 18px !important;
  margin-bottom: 2px;
}

.text-bold {
  font-weight: bold;
}

.additionalExpenses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border: 1px solid #d1d5db;
  height: 37px;
  border-radius: 7px;
}

.Additional_approved {
  padding: 5px;
  background: green;
  width: 75px;
  border-radius: 5px;
  color: white;
}

.Additional_approved:hover {
  cursor: not-allowed;
}

.Franchise_Documnets {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  cursor: pointer;
}

.Franchise_Documnets:hover {
  cursor: pointer;
}

.barcode-label {
  width: 40%;

  svg {
    width: 100% !important;
  }
}

.vw100 {
  width: 90vw !important;
}

.max-100-barcode {
  text-align: center;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

//access denied pages style

#AccessDeniedPages {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .Accesss_Denied_icon {
    text-align: center;
    color: gray;
  }

  .ACC_letter1 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 15px;
  }

  .ACC_letter2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
  }

  .redirect_page {
    text-align: center;
    margin-top: 15px;
  }
}

.custom_badges {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: red;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.custom_error_additional {
  color: #fd625e;
  font-size: 12.25px;
}

.location-map {
  display: flex;
  gap: 10px;

  svg {
    width: 20px;
  }
}

.uploaded_franchise_document {
  padding: 5px 20px;
  border: 1px solid #e4e4e7;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  max-width: 500px;
  justify-content: space-between;
  align-items: center;

  .uploade_file_name {
    font-weight: 600;
    font-size: small;
  }

  .upload_franchise_icon {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .upload_franchise_icon:hover {
    cursor: pointer;
  }
}

.TextArea_With_Map {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.TextArea_With_Map:hover {
  cursor: pointer !important;
}

.showVehicle_brand {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eae9ef;
  padding: 10px;
}

.showVehicle_brand_text {
  font-size: 14px;
  font-weight: 600;
}

.parentCls3new {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

@media screen and(max-width:1200px) {
  .parentCls3new {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}

@media screen and(max-width:650px) {
  .parentCls3new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
  }
}

.AdjustmetnCls {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.childClsNew {
  display: grid;
  grid-template-columns: 210px calc(100% - 250px);

  div:first-child {
    color: #616161;
  }

  div:last-child {
    font-weight: 600;
    color: #515151;
  }

  a {
    font-weight: 500;
    color: blue;

    &:hover {
      text-decoration: underline !important;
    }
  }

  span {
    svg {
      width: 17px !important;
      justify-content: end !important;
      margin: 0px 5px !important;
    }
  }
}

#incentiveReports {
  .AdjustmetnCls {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .childClsNew {
    display: grid;
    grid-template-columns: 150px calc(100% - 250px);
  }

  @media screen and (max-width: 1850px) {
    .AdjustmetnCls {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }

  @media screen and (max-width: 1300px) {
    .AdjustmetnCls {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }

    .childClsNew {
      display: grid;
      grid-template-columns: 210px calc(100% - 250px);
    }
  }

  @media screen and (max-width: 567px) {
    .childCls {
      display: grid;
      gap: 10px;
      grid-template-columns: 50% 1fr;
    }

    .childCls>div {
      overflow-wrap: anywhere;
    }

    .childClsNew {
      grid-template-columns: 50% 1fr !important;
      gap: 10px;
    }
  }

  .highStatus {
    color: #bc5090;
    background-color: #fbe1ff;
    border: 1px solid #f3b2fe;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .low {
    color: #007958;
    background-color: #e0f5f0;
    border: 1px solid #b4e5d9;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .general-details {
    padding: 10px;
    color: #374151 !important;
    background: #f8f8f8 !important;
    font-weight: 600 !important;
    width: 100%;
    float: none;
    font-size: 14px;
  }

  #paymentDetails {
    .AdjustmetnCls {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
    }

    .childClsNew {
      min-width: 200px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    gap: 20px;
  }

  .justify {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .justify-new {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1600px) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      padding: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 10px;
      padding: 10px;
    }
  }
}

// @media screen and (min-width:598px) and (max-width:992px) {
//   .calendar_btns{
//     margin: 7px 40px 0 0;
//   }
// }

@media screen and (max-width: 1100px) {
  .custom_align {
    justify-content: start !important;
  }

  .export_btns {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 992px) {
  .calendar_btns {
    top: 0px;
    position: absolute;
    z-index: 99;
    left: 130px;
  }
  .new-calendar {
    top: -5px !important;
    position: absolute;
    z-index: 99;
    left: 230px !important;
  }

  .align_justify {
    justify-content: center;
  }

  .align_width {
    width: 100%;
  }

  .label_margin {
    margin: 8px 0 8px 0;
  }
}

@media screen and (max-width: 598px) {
  .table-header-container .table-header-container-item:nth-child(n + 3):not(:first-child) {
    justify-content: end;
    row-gap: 10px;
    width: 100%;
  }

  .table-header-container {
    justify-content: start !important;
  }

  .table-header-container .table-header-container-item-special {
    margin-left: 0px !important;
  }

  .reponsive_position {
    width: 100% !important;
  }

  .add_btn {
    width: 100%;
  }

  #customize-table {
    margin-left: 20px !important;
  }

  .p-datatable-header {
    padding-right: 0;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    min-width: 2rem;
  }

  .p-paginator-pages {
    max-width: 100px;
    overflow: hidden;
    text-wrap: nowrap;
  }

  .remove_lables {
    display: none;
  }

  .btn_space {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    margin: 0px 2px !important;
    padding: 5px 8px !important;
  }

  .btn_space:hover {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  .feather-trash-2 {
    margin-right: 0px !important;
  }

  #bulk-action-status .status {
    margin-left: 5px !important;
  }

  .p-paginator-current {
    line-height: 1.2;
    text-align: center;
    padding: 0;
  }
}

@media screen and (max-width: 375px) {

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    min-width: 24px !important;
  }

  .btn_space {
    margin: 0px !important;
  }
}

.camera-icon-input {
  position: relative;
  background: #cecece;
  color: rgb(53, 53, 53);
  height: 38px;
  align-items: center;
  display: flex;
  padding: 5px;
  border-radius: 0px 5px 5px 0px;
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      max-width: unset !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

#new_upload_design {
  display: grid;
  grid-template-columns: 1fr 100px 20px;
  align-items: center;
  gap: 10px;
  padding: 5px;

  .uploaded_files_preview {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.p-fileupload-content {
  padding: 5px;
  height: 113px;
  overflow: auto;
  max-height: 113px;
}

.uploadOptionNewStyle {
  display: none;
}

.p-fileupload {
  margin-top: 28px;
}

.custom-choose-btn {
  background: white !important;
  border-radius: 50%;
  border: 1px solid #00cff2 !important;

  .upload {
    color: #00cff2;
  }
}

.custom-cancel-btn {
  background: white !important;
  border-radius: 50%;
  border: 1px solid #ef4444 !important;

  .cancel {
    color: #ef4444;
  }
}

.document-view {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px;
}

#bulkEmail {
  .p-tabview-panels {
    max-height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(211, 211, 211, 0.5) transparent;
    scroll-behavior: smooth;
  }
}

.common-status-intend {
  border-radius: 5px;
  font-weight: 700;
  padding: 2px 5px;
  width: fit-content;
}

.success-status {
  background-color: #dcffe2;
  color: #3f8149;
}

.received-status {
  background-color: #def7ff;
  color: #0091d9;
}

.couriered-status {
  background-color: #fffbd4;
  color: #939300;
}

.info-status {
  background-color: #ffeee7;
  color: #fb6d2f;
}

.danger-status {
  background-color: #ffaeae82;
  color: #ef4444;
}

.warning-status {
  background-color: #9db3d293;
  color: #586c87;
}

.miscellaneous-status {
  background-color: #ffdd004b;
  color: #826f00;
}

.dialog_whole_section {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  width: 360px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f6f6f6;
  border-radius: 10px;

  .close_Menu {
    position: absolute;
    top: -28px;
    right: 0;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    // margin-right: 10px;
    font-size: 15px;
    text-transform: uppercase;

    .close_menu_Cross :hover {
      cursor: pointer !important;
    }
  }

  .dialog_header {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    padding: 15px;
    text-transform: uppercase;
  }

  .logo_container {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-container {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #3498db;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        color: #ffffff;
        font-size: 24px;
      }
    }
  }

  .otp_dialog_container {
    padding: 0 10px;

    .otp_sologan {
      text-align: center;
      margin-top: 10px;
      font-weight: 700;
    }

    .otp_dialog_button_groups {
      padding: 15px 0;

      .btnsnsn {
        // display: flex;
        // justify-content: space-between;
      }
    }
  }
}

// }

.balckscreen {
  background: #00000099;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  position: fixed;
  top: 0;
  font: normal normal normal 14px/20px Roboto;
  left: 0;
}

.otp_input_design {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  input {
    width: 40px;
    height: 40px;
    border: 1px solid #4338ca;
    text-align: center;
  }
}

.dropzone_disabled {
  opacity: 0.5;
  pointer-events: none;
}

.franchise_upload_document_header {
  padding: 0 15px;
  font-weight: 600;
  letter-spacing: 1px;
}

.jobsheet_tab_header {
  background: #f0f0f0 !important;
  padding: 10px;
  margin-bottom: 10px;

  .franchise_reached {
    color: green;
    font-weight: bolder;
    border: 1px solid greenyellow;
    font-size: smaller;
    padding: 2px;
    border-radius: 5px;
    background: #ebfbd8;
  }
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  border: 1px solid grey;
  cursor: pointer;
}

#feedback_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  // width: 360px;
  // height: 215px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f6f6f6;
  border-radius: 10px;

  .close_Menu {
    position: absolute;
    top: -28px;
    right: 0;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 15px;
    text-transform: uppercase;

    .close_menu_Cross :hover {
      cursor: pointer !important;
    }
  }

  .feed_back_body {
    .feed_back_headers {
      height: 60px;
      background: #ffeac7;
      display: grid;
      place-items: center;
      border-bottom: 1px solid #e1dede;

      .text {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .text_btn_container {
      padding: 10px;

      .text {
        font-weight: 600;
      }

      .btn_container {
        margin: 20px 0;
      }
    }
  }
}

.additional-expense-no-expense {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.manual-sku {
  margin-left: 5px;
  background-color: rgba(238, 174, 174, 0.828);
  color: red;
  font-weight: bold;
  width: fit-content;
  font-size: 10px;
  padding: 2px;
}

.partSkuNumber {
  span {
    padding: 1px 4px;
    background: #6969fd;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #9999dc;
    margin-left: 10px;
    font-size: 5px;
    font-weight: 600;
  }
}

.checkbox-disable {
  color: #abadac !important;
}

.checkbox-highlight {
  background: #c8f0f6;
  width: fit-content;
  padding: inherit;
  border-radius: 2px;
}

#parts_choice_dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  width: 500px;
  height: 225px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f6f6f6;
  border-radius: 10px;

  .close_Menu {
    position: absolute;
    top: -28px;
    right: 0;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 15px;
    text-transform: uppercase;

    .close_menu_Cross :hover {
      cursor: pointer !important;
    }
  }

  .body_content_data {
    .header_section {
      text-align: center;
      height: 35px;
      background: #0082ff;
      color: #fff;
      display: grid;
      place-items: center;
    }

    .show_details {}

    .bottom_section {
      position: absolute;
      bottom: 0;
      background: #e5e5e5;
      display: block;
      width: 100%;
      // height: 35px;
    }
  }
}

.highlighted-dropdown {
  border: 2px solid #007bff;
  /* Blue border color */
}

#customized-timeline {
  .p-timeline-event {
    max-width: 400px;
  }
}

#new_timeLine {
  position: relative;
  width: 100%;
  padding: 30px 0;

  .timeline-container {
    display: flex;
  }

  .timeline-start,
  .timeline-year {
    position: relative;
    // width: 100%;
    text-align: end;
    z-index: 1;

    p {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 0;
      padding: 16px 0;
      text-align: center;
      background-color: #5156be;
      border-radius: 100px;
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .timeline-end {
    position: relative;
    z-index: 1;

    p {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 0;
      padding: 16px 0;
      text-align: center;
      background-color: #5156be;
      border-radius: 100px;
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .timeline-continue:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    top: 55px;
    left: 0;
    margin-left: -1px;
    background: #5156be;
  }

  .timeline-continue {
    width: 100%;
  }

  .timeline-icon::after {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    // top: 45px;
    top: 18px;
    background: #5156be;
    border-radius: 15px;
    z-index: 1;
    left: 60px;
  }

  .timeline-box {
    position: absolute;
    padding: 15px;
    border: 1px solid var(--bs-border-color);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 200px;
    left: 100px;
    margin-top: -85px;
  }

  .timeline-date {
    width: 40px;
    height: 50px;
    display: inline-block;
    padding: 5px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
    top: -10px;
    position: absolute;
    z-index: 1;
  }

  .timeline-bottom {
    margin-left: 270px;
    position: absolute;
    bottom: -48px;
    width: 200px;

    .timeline-icon::after {
      content: "";
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      top: -110px;
      background: #5156be;
      border-radius: 15px;
      z-index: 1;
      left: 60px;
    }

    .timeline-box {
      position: absolute;
      padding: 15px;
      border: 1px solid var(--bs-border-color);
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      left: 50px;
    }

    .timeline-date {
      width: 40px;
      height: 50px;
      display: inline-block;
      padding: 5px;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
      top: -10px;
      position: absolute;
      z-index: 1;
    }
  }
}

.fullWidthOfdotm {
  overflow: auto;
  height: 400px;
}

.layoutdiv {
  position: relative;
  margin: 190px 45px 0;
  border: 1px solid #eee;
  overflow: visible;
}

// .layoutdiv::before {
//   content: "Start";
//   position: absolute;
//   left: -40px;
//   top: -10px;
//   display: inline-block;
//   width: 50px;
//   height: 50px;
//   margin: 0;
//   padding: 16px 0;
//   text-align: center;
//   background-color: #5156BE;
//   border-radius: 100px;
//   color: #fff;
//   font-size: 12px;
//   text-transform: uppercase;
// }

.dotmark {
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  border-radius: 10px;
  top: -10px;
}

.clildDivtop {
  position: absolute;
  left: 0;
  top: 20px;

  .timeline-right {
    margin-top: 10px;
    margin-left: 5px;

    .timeline-box {
      padding: 15px;
      border: 1px solid var(--bs-border-color);
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 200px;
    }

    .timeline-date {
      width: 35px;
      height: 45px;
      display: inline-block;
      padding: 2px;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
      top: -10px;
      position: absolute;
      z-index: 1;
    }
  }
}

.clildDivbottom {
  position: absolute;
  left: 0;
  bottom: 20px;

  .timeline-right {
    margin-top: 10px;
    margin-left: 5px;

    .timeline-box {
      padding: 10px;
      border: 1px solid var(--bs-border-color);
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 200px;
    }

    .timeline-date {
      width: 35px;
      height: 45px;
      display: inline-block;
      padding: 2px;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
      top: -10px;
      position: absolute;
      z-index: 1;
    }
  }
}

.status_of_courier_cancel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 115px;
  // width: fit-content;
  color: red;
  font-weight: 600;
}

.status_of_courier {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 115px;
  // width: fit-content;
  color: rgb(66, 151, 236);
  font-weight: 600;
}

.map-container {
  width: 100%;
  height: 30vh;
  margin-top: 10px;
  border-radius: 10px;
}

.highlight-chip {
  background-color: rgba(255, 208, 54, 0.884) !important;
  color: rgb(0, 0, 0) !important;
}

.map-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(234, 234, 234, 0.499);
  font-weight: 600;
  width: 100%;
  height: 30vh;
  margin-top: 10px;
  border-radius: 10px;
}

.btn-primary-shadow {
  background: linear-gradient(0deg, #423583, #6853d2) !important;
}

.btn-primary-shadow {
  border: 1px solid #fff !important;
}

.btn-primary-shadow:active {
  border: 1px solid #fff !important;
}

.next-status-btn {
  // background: linear-gradient(0deg, #c06100, #f48a1d) !important;
  // background: linear-gradient(0deg, #051c5f, #214fd8) !important;

  background-color: #529ef9 !important;
  // background-color: #0f3cc9 !important;
  color: white !important;
  font-weight: 500;
}

.payment-button {
  // background: linear-gradient(0deg, #5546a0, #7e67f3) !important;
  background-color: #2371ec !important;
  border-radius: 25px;
  outline: none;
  border: none;
  color: white;
  padding: 3px 10px;
}

.text_editor_error {
  border: 1px solid red;
}

.text_editor_disabled {
  height: 140px;
  overflow: auto;
  border: 1px solid #ebecef;
  padding: 5px;
  border-radius: 5px;
  background: #d9d9d9;
}




.last_modified {
  font-size: 13px;
  font-weight: 700;
  color: darkslategray;
}

.float-disabled {
  input {
    background-color: #0000003f !important;
  }

  .p-disabled {
    background-color: #0000003f !important;
  }
}

#Approve_Rejection_Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  width: 360px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f6f6f6;
  border-radius: 10px;

  .close_Menu {
    position: absolute;
    top: -28px;
    right: 0;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 15px;
    text-transform: uppercase;

    .close_menu_Cross :hover {
      cursor: pointer !important;
    }
  }

  .dialog_header {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    padding: 15px;
    text-transform: uppercase;
  }

  .content_text {
    text-align: center;
    font-size: 16px;
    text-transform: math-auto;
  }

  .btn_section {
    padding: 15px;
  }
}


.p-inputswitch {
  width: 40px !important;
  height: 20px !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 14px !important;
  height: 14px !important;
  left: 2px !important;
  margin-top: -7px !important;
  border-radius: 50%;
  transition-duration: 0.2s;
}

#emailpreview {
  img {
    max-width: 95% !important;
    height: auto;
  }
}

#email-input {
  input::placeholder {
    font-size: 12px;
    font-weight: bold;
  }
}

.p-datatable-wrapper {
  max-height: calc(100vh - 276px) !important;
}

@media only screen and (max-width:598px) {

  .p-datatable .p-datatable-thead>tr>th:nth-child(-n+2),
  .p-datatable .p-datatable-tbody>tr>td:nth-child(-n+2) {
    width: auto !important;
    left: auto !important;
  }
}

.estimate_amount div:nth-child(even) {
  text-align: right;
  max-width: 200px;
}

.p-autocomplete-items-wrapper {
  ul {
    padding-left: 0px !important;
  }
}

.p-image-toolbar {
  .p-image-action[data-pc-section="zoominbutton"] {
    display: none !important;
  }

  .p-image-action[data-pc-section="zoomoutbutton"] {
    display: none !important;
  }
}

.empty-number-jobeheet {
  display: flex;
  gap: 5px;

  span {
    background-color: grey;
    padding: 2px;
    border-radius: 50%;
  }
}

.p-autocomplete-items-wrapper {
  ul {
    padding-left: 0px !important;
  }
}

.p-image-toolbar {
  .p-image-action[data-pc-section="zoominbutton"] {
    display: none !important;
  }

  .p-image-action[data-pc-section="zoomoutbutton"] {
    display: none !important;
  }
}

.empty-number-jobeheet {
  display: flex;
  gap: 5px;

  span {
    background-color: grey;
    padding: 2px;
    border-radius: 50%;
  }
}


.Map_input_Field {
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #eae9ef;
}

.gm-style-iw-chr {
  height: 25px !important;

  .gm-ui-hover-effect {
    width: auto !important;
    height: auto !important;

    span {
      margin: 0 !important;
    }
  }
}

.map_infocard_title {
  color: #5156BE;
}

.partNumber_Header {
  position: unset !important;
}

.Sku_eye_View {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .Sku_view_align {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.jobsheet-filter{
  .p-inputtext{
    padding:2px !important;
    max-width: 200px;
    input{
      background-color: #f8f8f8af;
    }
  }
  .p-autocomplete-token{
    padding: 2px 5px;
    .p-autocomplete-token-label{
      font-size: 12px;
    }
  }
}

.jobsheet-filter-parent{
  position: absolute;
  top:145px;
  right:10px;
  background-color: white;
  z-index: 999;
  width:400px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  hr{
    margin:0px !important;
  }
.header-jobsheet{
  padding: 10px 20px;
  background-color: #5156BE;
  svg{
    color:white;
  }
  h5{
    color:white;
    margin-bottom: 0px;
  }
}
.job-filter-container{
  padding: 10px 20px;
.filter-content{
  label{
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .p-multiselect-label{
    padding:6px;
  }
  ul{
    margin-bottom: 2px;
  }
  .p-inputtext{
    padding: 6px !important;
  }
  .p-chips-token{
    padding:3px 5px;
    .p-chips-token-label{
      font-size: 12px;
    }
  }
}
}
}
.reponsive_position-jobsheet{
  padding: 9px 6px;
  margin-left: 3px;
  background-color: #5156be;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  svg{
    margin-right: 0px !important;
  }
}

.pending-information{
  p,ul{
    // width: 400px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
    width: 400px;             
    word-break: break-all;    
    white-space: normal; 
  }
}
.pending-information-text{
  width: 400px;             
  word-break: break-all;    
  white-space: normal; 
  p,ul{
    width: 400px;             
    word-break: break-all;    
    white-space: normal; 
  }
}
.pending-information-large{
  max-width: 1000px;             
  word-break: break-all;    
  white-space: normal; 
  p,ul{
    max-width: 1000px;             
    word-break: break-all;    
    white-space: normal; 
  }
}
.pending-information-full{
  max-width: 100%;             
  word-break: break-all;    
  white-space: normal; 
  p,ul{
    max-width: 100%;             
    word-break: break-all;    
    white-space: normal; 
  }
}

.hover-class {
  max-width: unset !important;
  max-height: 20px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  
  div {
    max-height: 20px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    
    :hover {
      max-height: 300px;
    }
  }

  :hover {
    max-height: 300px;
    
    div {
      max-height: 300px;
    }
  }
}
.margin-top-4{
  margin-top: 2.5rem !important;
}

.btn-group-tat{
  button{
    font-size: 12px !important;
  }
}

.partSku{
  &:hover{
    max-width:fit-content !important;
    transition: width 5s ease;
  }
}

.custom-input{

  .inp-icon{
    width: 100%;
  }
  .get-detail{
    width: 100px;
    height: 40px;
  }
}

.BulkTemplateClass{
  width: max-content;
}

.view-jobsheet{
  button{
    display: flex;
    justify-content: center;
    svg{
      margin-right: 5px !important;
      color: white !important;
      width: 16px !important;
    }
    i{
      margin-right: 5px !important;
      color: white !important;
      font-size: 20px !important;
    }
  }
  .btn-success{
    box-shadow: none !important;
    border: 1px solid #e9e9ef !important;
  }
}
.p-editor-toolbar{
  display: none;
}
#view-brand{
  .p-editor-toolbar{
    display: block !important;
  }
}
#courier{
 .p-datepicker-trigger {
    background-color: #f8f9fa !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: 1px solid white !important;
    svg{
      color:#604eb8 !important;
    }
}
}
.button-sticky{
  position: fixed;
  right: 40px;
  bottom: 70px;
  width: auto;
  background-color: white;
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  button{
    width: 150px;
    border-radius: 20px;
  }
}
.text-approve{
  color:green
}

.text-reject{
  color:red
}

.incoice-action{
  display: flex;
  gap: 20px;
    i{
      font-size: 25px;
      font-weight: 400;
      
    }
    .whatsapp{
      cursor: pointer;
      i{
        color:green;
      }
    }
    .mail{
      cursor: pointer;
      i{
        color:rgb(247, 68, 68);
      }
    }
    .print{
      cursor: pointer;
      i{
        color:rgb(42, 59, 158);
      }
    }
}

.incoice-edit{
  svg{
    width: 22px;
    cursor: pointer;
  }
}
.faulty-system{
  padding: 9px;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .data-content{
    display: flex;
    flex-wrap: wrap;
    gap:20px;
    max-height: 250px;
    overflow-y: auto;
    label{
      width: max-content;
    }
    .data{
      display: flex;
      gap:20px;
      .label{
        font-weight: 600;
        color:black;
      }
    }
  }
  .header{
    font-size: 16px;
    font-weight: 500;
  }
}