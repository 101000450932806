.subUserPage {
    padding: calc(70px + 1.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
    // background: #f7f7f7;
    // height: 100vh;
}
body[data-layout="horizontal"] {
    .subUserPage {
        padding: calc(70px + 3.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
    }
    }
