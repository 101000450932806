// Z-index
.z-index {
    position: relative;

    .box-2 {
        top: 13px;
        left: 16px;
    }

    .box-3 {
        top: 25px;
        left: 31px;
    }

    .box-4 {
        top: 39px;
        left: 45px;
    }

    .box-5 {
        top: 52px;
        left: 59px;
    }
}
.text-additional-dark{
    color: rgba(119, 122, 202,1) !important
}
.text-additional-light{
    color: rgba(168, 170, 218,1) !important
}