#bread-crumb-with-filter {
  svg {
    width: 20px;
    color: var(--bs-sidebar-menu-item-icon-color);
    fill: var(--bs-sidebar-menu-item-icon-effact-color);
    margin-right: 10px;
    margin-top: -3px;
  }
  svg:hover {
    color: #5156be;
    fill: var(--bs-sidebar-menu-item-hover-effact-color);
    cursor: pointer;
  }
  .active svg {
    color: #5156be !important;
    fill: var(--bs-sidebar-menu-item-active-effact-color);
  }

  .breadcrumb {
    padding-left: 0px;
    padding-right: 30px;

    margin-right: 10px !important;
  }

  .br-rg{
    border-right: 2px solid #cfcfcf;
  }

  .wrap-flex{
    flex-wrap: wrap;
  }
}


@media screen and (max-width: 767px) {
  #bread-crumb-with-filter .wrap-flex{
    margin-bottom: 10px;
  }
  .page-title-right{
    margin-top: 10px;
  }
  #bread-crumb-with-filter .breadcrumb{
    padding-right: 0;
  }
  #bread-crumb-with-filter .wrap-flex button{
    width: 50%;
  }
}

@media screen and (max-width: 568px) {
  #bread-crumb-with-filter .wrap-flex button{
    width: 50%;
  }

  #bread-crumb-with-filter .wrap-flex button:last-child:nth-child(odd){
    width: 100% !important;
  }
}