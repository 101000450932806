#email-body-template{
  .email-tag{
    text-decoration: none;
    color: black !important;
  }
  
  .email-tag:hover{
    text-decoration: underline !important;
    cursor: pointer !important;
    color: black !important;
  
  }
}
