#custom_modal_body {
    max-width: 855px;

    #AttendanceDetails {
        .header_style {
            background: #68657e;
            color: white;
            border-radius: 5px;
            padding: 5px;
            margin: 0;

            .Attendance_headerSection {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .Attendance_Header_title {
                    font-size: medium;
                    font-weight: 600;
                    line-height: 14px;
                }
    
                .close_button {
                    padding: 5px;
                    font-size: medium;
                    font-weight: bold;
                }
    
                :hover {
                    cursor: pointer;
                }
            }
        }

    }
}