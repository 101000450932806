#file-previews{

    .img-container{
        .image-size{
            width: 150px !important;
            height: 150px !important;
        }
        .img-del-icon{
            display: none;
        }
    }
   

    .img-container:hover{
        .image-size{
            filter:blur(4px);
        }
        .img-del-icon{
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            display: block;
            background-color: aliceblue;
            padding: 5px;
            border-radius: 1px;
            cursor: pointer;
        }
    
        .img-del-icon:hover{
            font-weight: 900;
            
        }
    }
    

  
}

