#audiobrandPage {
	padding: calc(70px + 1.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
	// height: 100vh;

	.inactiveStatus {
		font-size: 8px;
		text-transform: uppercase;
		background: red;
		padding: 3px;
		color: white;
		border-radius: 3px;
	}

	.audioModel {
		font-size: 13px;
		font-weight: 600;
	}

	.activeStatus {
		// font-size: 8px;
		font-size: 10px;
		text-transform: uppercase;
		background: green;
		padding: 3px 5px;
		color: white;
		border-radius: 3px;
	}
}

body[data-layout="horizontal"] {
	#audiobrandPage {
		padding: calc(70px + 3.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
	}	
    }

	@media screen and (max-width:567px){
		#audiobrandPage {
			padding: calc(70px + 0.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
		}
		
		body[data-layout="horizontal"] {
	#audiobrandPage {
		padding: calc(70px + 0.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
	}	
    }
}