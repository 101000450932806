#cusomerPage {
  padding: calc(70px + 1.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);

}
body[data-layout="horizontal"] {
  #cusomerPage {
    padding: calc(70px + 3.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
  
  }
}
.excel {
  padding: 10px;
  margin-right: 5px;
  background: #3ab73a;
  border-radius: 5px;
  font-size: 12px;
  color: antiquewhite;
  letter-spacing: 1px;
}

.excel:hover {
  cursor: pointer !important;
}

.pdf:hover {
  cursor: pointer !important;
}

.pdf {
  padding: 10px;
  margin-right: 5px;
  background: #dc4343;
  border-radius: 5px;
  font-size: 12px;
  color: antiquewhite;
  letter-spacing: 1px;
}

#export-btn {
  .flex {
    display: flex;
  }

  h6 {
    margin-top: 10px;
  }

  .field-list-container {
    max-height: 30vh;
    overflow-y: auto;
  }

  .no-match-found {
    color: blue;
  }


  .span-total-column-display {
    background: #dfeffc;
    color: black;
    padding: 0px 5px;
    border-radius: 3px;
  }

  .btn-container {
    display: flex;
    border-top: 1px solid #e9e9ef;
    padding-top: 10px;
  }

}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 10px 1.25rem 10px !important;
}


.p-sortable-column-icon.pi-sort-up::before {
  content: '\f106'; /* Unicode character or custom content for the ascending icon */
}

.p-sortable-column-icon.pi-sort-down::before {
  content: '\f107'; /* Unicode character or custom content for the descending icon */
}

.pi-filter::before {
  content: '\f123'; /* Unicode character or custom content for the filter icon */
}