#view-dealer {
  .dealer-details {
    td {
      padding: 5px 0px;
    }
  }
  tr {
    border-bottom: 1px solid var(--bs-border-color);
  }
}

body[data-layout="horizontal"] {
.pagescontent {
  padding: calc(70px + 4.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75) !important;
}
}
.pagescontent {
  padding: calc(70px + 1.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
}
.validation-error-index{
  color: white;
  padding: 0px 5px;
  background: red;
  border-radius: 50%;
  margin-bottom: 2px;
}

.validation-error-line{
  margin-left: 10px;
}

.dynamic-form-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    // align-items: center;
    // justify-content: end;
}

.space-equally,.space-equally-3{
  flex-grow: 1;
  margin-top: 10px;
}

.space-equally-last{
  display: flex;
    gap: 5px;
    // align-items: end;
    margin-top: 25px;
}

.space-equally-2{
  align-self: center;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  i{
    font-size: 15px;
  }
}

.add-btn-container{
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: #604eb8;
  font-size: 18px;
  cursor:pointer;
  i{
    margin-right: 5px;
  }
  // width: fit-content;
}



.dealer-title-header{
  background: #f0f0f0 !important;
    padding: 10px;
    margin-bottom: 10px;    
    div{
      font-size: large;
      font-weight: 500;
    }
}

.dealer-newtitle-header{
  background: #f0f0f0 !important;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    
    div{
      font-size: large;
      font-weight: 500;
    }
}
.space-equally-3{
  width: 10% !important;
  .form-control{
    width: 100% !important;
  }
}


  
@media screen and (min-width:845px) and (max-width: 1121px){
  .space-equally{
    max-width: calc(100% - 78%);
  }
}
@media screen and (max-width:767px){
  .space-equally{
   flex-grow: 0 !important;
   width: 100%;
  }
}

@media screen and (min-width:1000px){
  .space-equally,.space-equally-3{
    .is-invalid{
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
    input[type="number"] {
      -moz-appearance: textfield;
      // width: 150px;
    }
    
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

