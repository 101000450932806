// header page style

#ticket-system {
  .newPage {
    padding: 75px 0px;


    .ticket_HeaderPage {
      border-bottom: 1px #ebeff3 solid;
      padding: 5px 10px;
      background: #fff;

      .flter_block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ticket_filterblock {
          display: flex;
          align-items: center;

          .feather_icon:hover {
            cursor: pointer;
          }

          .ticket_filterstatus {
            padding-left: 20px;
            font-size: 16px;
            color: #183247;
          }
        }

      }

      .headers_remainParts {
        display: flex;
        align-items: center;
        gap: 20px;

        .getStartedButton {
          padding: 8px;
          border-radius: 5px;
          background-color: #fff;
          color: #12344d;
        }
      }
    }
  }

  .ticket_HeaderPagess {
    border-bottom: 1px #ebeff3 solid;
    padding: 5px 10px;
    background: #fff;

    .second_column {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-right: 70px;

      .flter_block2 {
        padding-top: 10px;
        display: flex;
        align-items: center;
        gap: 15px;

        .status_text {
          color: #12344d;
          font-size: 18px;
        }
      }

      .card_view_header {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }

  .ticket_contentPage {

    .filter_Details {
      background-color: #ebeff3;
      border-right: 1px solid #eaeaea;
      width: 200px;
      height: 100%;
      position: fixed;

      .filter_data {
        padding: 10px;
        color: #12344d;
      }
    }
  }

  .ticket_system_loaded {

    display: flex;

    .ticketSystem_sidebar {
      display: block;
      height: 100%;
      max-height: 100%;
      background: #f2f2f2;
      position: fixed;
      width: 60px;
      text-align: center;
      border-right: 1px solid #e8e8e8;

      ul {
        list-style: none;
        padding: 0.5rem 0.3rem;

        .active {
          color: #5f2bc5 !important;
        }

        li:hover .nav-link svg {
          color: #5f2bc5 !important;
        }

        li.active {
          background: #fff;
        }

        li:hover {
          background-color: #e8e0ff8c;
          cursor: pointer;
        }

        a {
          padding: 10px 5px;
        }
      }
    }

    .contentof_ticketsystem {
      width: 100%;

      .ticket_card {
        min-height: 92px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 1px 0 0 #cfd7df;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        align-items: center;
        margin: 5px 10px;

        .ticket_content_page {

          width: 100%;
          display: grid;
          grid-template-columns: 70% 30%;

          .ticket_img_content {
            display: flex;
            align-items: center;
            gap: 20px;

            .ticket_img {
              width: 50px;
              height: 50px;
              padding: 5px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .content_ticket {}
          }

          .show_Details {
            .align_ticketStatus {
              display: flex;
              gap: 15px;
              align-items: center;

              .box {
                display: block;
                width: 13px;
                height: 13px;
                background-color: rgb(55, 210, 210);
              }
            }
          }
        }
      }
    }
  }


  .statusHighlight {
    color: #007958;
    background-color: #e0f5f0;
    border: 1px solid #b4e5d9;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .MediumStatus {
    color: #008eff;
    background-color: #dff5ff;
    border: 1px solid #a8cae6;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .highStatus {
    color: #BC5090;
    background-color: #fbe1ff;
    border: 1px solid #f3b2fe;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .urgentStatus {
    color: #F44336;
    background-color: #fff0ef;
    border: 1px solid #ffdee9;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .low {
    color: #007958;
    background-color: #e0f5f0;
    border: 1px solid #b4e5d9;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .open {
    color: #008eff;
    background-color: #dff5ff;
    border: 1px solid #a8cae6;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .pending {
    color: #F44336;
    background-color: #fff0ef;
    border: 1px solid #ffdee9;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .reassign {
    color: orange;
    background-color: #ffecc8;
    padding: 3px;
    border: 1px solid;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .reopen {
    color: #510136;
    background-color: #ffcfef;
    border: 1px solid;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .resolve {
    color: #FF9D00;
    background-color: #fff8ee;
    border: 1px solid #ffdca9;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .stclose {
    color: #007958;
    background-color: #e0f5f0;
    border: 1px solid #b4e5d9;
    padding: 3px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }


  .attachment-container {
    font-weight: 700;
    color: #604eb8;
    display: flex;
    align-items: center;
    justify-content: normal;
  }

  .attachment-icon {
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
  }


  .selected-file-container {
    padding: 10px;
    border: 1px solid #eae9ef;
    margin: -10px;
    margin-top: 0px;
    margin-bottom: 10px;
    border-radius: 5px;

  }

  .selecte-file-item {
    padding: 10px;
    margin: -10px;
    margin-top: 0px;
    margin-bottom: 0px;
    // background: #795eff;
    // color: white;
    border-top: 1px solid #eae9ef;
  }

  .selecte-file-item:hover {
    background: #f3f4f6;
  }

  .seleted-file-item-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .selected-file-delete-icon {
    color: red;
    padding: 5px;
    border-radius: 50%;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
  }

  .selected-file-delete-icon:hover {
    background: #ff000047;
  }

  .reply-container {
    padding: 10px;
    margin: -10px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid #eae9ef;
    border-radius: 5px;
  }

  .reply-sent-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #eae9ef;
    padding: 5px;
    border-top: none;
  }

  .reply-sent-btn-container {
    display: flex;
    justify-content: space-between;
  }

  .send-btn {
    padding: 0px 5px;

    border-radius: 5px;
    cursor: pointer;
  }

  .send-btn:hover {
    // font-size: 15px;
    cursor: pointer;
    border: 1px solid #0000ffbd;
  }

  .close-btn {
    padding: 0px 5px;
    color: black;
    border-radius: 5px;
    cursor: pointer;
  }

  .close-btn:hover {
    // font-size: 15px;
    cursor: pointer;
    border: 1px solid black;
  }


  .reply-attachment-container {
    padding: 5px;
    display: grid;
    grid-template-columns: 300px auto;
    align-items: center;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
  }

  .reply-attachment-container:hover {
    background: #f3f4f6;
  }

  .reply-attachment-alignment {
    border: 1px solid #eae9ef;
    border-top: none;
  }

  .message-box-container {
    padding: 0.7rem;
    border: 1px solid #ebe9ef;
    // border-radius: 5px;
    border-radius: 1rem 1rem 0 1rem;
    margin-bottom: 10px;
    // margin-left: 10px;
    background: #e9e9ef;
    font-weight: 500;
    margin-top: 5px;
  }

  .message-box-container_response {
    padding: 0.7rem;
    border: 1px solid #ebe9ef;
    // border-radius: 5px;
    border-radius: 1rem 1rem 1rem 0;
    margin-bottom: 10px;
    // margin-left: 10px;
    background: #e9e9ef;
    font-weight: 500;
    margin-top: 5px;
  }

  .message-sender-container {
    display: flex;
    align-items: center;
  }

  .message-box-date {
    // margin-left: 10px;
    font-size: smaller;
    color: #795548;
  }

  .weight-700 {
    font-weight: 700;
  }

  .reply-msg-attachment {
    padding: 5px;
    font-weight: 500;
  }

  .reply-msg-attachment-container {
    margin: -10px 0px 12px 10px;
    border: 1px solid #e3dbd9;
    border-top: none;
    border-radius: 3px;
  }

  .reply-msg-attachment:hover {
    background: #f3f4f6;
  }

  .message-align-left {
    width: calc(100% - 50% - 10px);

    .message-box-container {
      background-color: #ebebeb !important;
    }
  }

  .message-align-right {
    width: calc(100% - 50% - 10px);
    margin-left: auto;

    .message-box-container {
      background-color: #d9d9d9 !important;
    }
  }

  .file-view-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }


  .viewCls {
    display: grid;
    grid-template-columns: 250px calc(100% - 250px);

    div:first-child {
      color: #616161
    }

    div:last-child {
      font-weight: 600;
      color: #515151;
    }

    a {
      font-weight: 500;
      color: blue;

      &:hover {
        text-decoration: underline !important;
      }
    }

    span {
      svg {
        width: 17px !important;
        justify-content: end !important;
        margin: 0px 5px !important
      }
    }
  }


  .viewTicketCls {
    display: grid;
    // grid-template-columns: 250px calc(100% - 250px);
    grid-template-columns: 150px 20px auto;
  }

  .reassignTicketCls {
    display: grid;
    grid-template-columns: 250px calc(100% - 250px);
    align-items: center;
  }

  @media screen and (max-width: 1200px) {
    .viewTicketCls {
      display: grid;
      // grid-template-columns: 191px 30%;
      grid-template-columns: 150px 20px auto;
      .weight-700{
        white-space: wrap;
      }

    }
  }

  @media screen and (max-width: 600px) {
    .viewTicketCls {
      display: block;

      div:first-child {
        max-width: 100%;
      }
      .childTicket{
        display: none;
      }
      div:last-child {
        margin-bottom: 10px;
      }
    }
  }

  .view-details-container {
    margin-bottom: 10px;
    hr{
      margin-top:0.7rem;
      margin-bottom:0.7rem;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  // #f3f4f6 hover
  // #e9e9ef message box color


  .file_structures {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 20px;
    color: #2b00fe;
    justify-content: end;
    padding: 0 20px 0 0;
    cursor: pointer;
  }

  .file_structures:hover {
    cursor: pointer;
  }

  .editedDate_file {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 24px;

    .fas {
      cursor: pointer;
    }
  }
}

