// 
// Page-title
// 

.page-title-box {
    padding-bottom: 0.8rem;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }
}