#file-previews_jobsheet {

    .img-container {
        display: flex;
        gap: 20px;
        align-items: center;

        .image-size {
            width: 115px !important;
            height: 115px !important;
        }

        .img-del-icon {
            display: none;
        }
    }


    .img-container:hover {
        .image-size {
            filter: blur(4px);
        }

        .img-del-icon {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            display: block;
            background-color: aliceblue;
            padding: 5px;
            border-radius: 1px;
            cursor: pointer;
        }

        .img-del-icon:hover {
            font-weight: 900;

        }
    }


}