.scanpart {
    width: 100%;
    padding: 5px 0;
    margin-bottom: -3px;
}

.custom-chip {
    background-color: #f8f9fa !important;

    .p-inputtext {
        background-color: #f8f9fa !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .p-chips-token {
        cursor: default;
        display: inline-flex;
        align-items: center;
        flex: 0 0 auto;
    }
}

.p-chips-input-token {
    padding: 0px;
    height: 21px;
    input {
        border: 0 none;
        outline: 0 none;
        background-color: #f8f9fa !important;
    }
}

li::marker {
    list-style-type: none;
}