#status-highlighter {
    .staus-highlighter-wrapper {
        text-align: center;
        width: fit-content;
        text-transform: capitalize;
        font-size: 12px;
        padding: 1px 3px;
        border-radius: 3px;
        font-weight: 700;
    }

    .yes {

        color: #4ba6ef;
        background-color: rgba(75, 166, 239, 0.18);
    }

    .no {

        color: #000000;
        background: rgba(33, 37, 41, 0.18);
    }

    .three {
        color: green;
        background: rgba(83, 197, 96, 0.18);
    }
    .one {
        color: red;
        background: rgba(213, 96, 63, 0.18);
    }
    .two {
        color: #000000;
        background: #d8d9d7ff;
        
    }
    .reply {
        color: #000000;
        background: #d8d9d7ff;
        
    }


}