#view-brand {
    .brand-details {
      td {
        padding: 5px 0px;
        min-width: 400px;
      }
    }
  
    tr {
      border-bottom: 1px solid var(--bs-border-color);
    }


    .p-column-filter-menu{
      margin: 0px !important;
      button{
        margin-left: unset;
      }
    }


  }

  .pagesscontent {
    padding: calc(70px + 1.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
    height: 100vh;
}


.btn-alignment{
  display: flex;
    align-items: baseline;
    margin-top: 28px !important;
}
.dealer-name{
  a{
    white-space: nowrap;
  }
}