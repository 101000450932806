#stock-location {
  tbody td {
    height: 40px;
    text-transform: capitalize;
  }

  .p-column-filter-menu {
    margin: 0px !important;

    button {
      margin-left: 120px;
    }
  }
}

.btn-alignment {
  display: flex;
  align-items: baseline;
  margin-top: 28px !important;
}