.custom-table {
    border-collapse: collapse;
    width: 100%;
  }

  .custom-table th,
  .custom-table td {
    border: 1px solid #ddd; /* Customize the border color */
    padding: 8px;
    text-align: left;
  }


.dealer-details {
  td {
    padding: 5px 0px;
  }
}

tr {
  border-bottom: 1px solid var(--bs-border-color);
}

.page {
  padding: calc(70px + 1.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
}
