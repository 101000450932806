#reward {
  .grid-container {
    display: grid;
    grid-template-columns: 100px calc(100% - 100px);
    gap: 10px;
    align-items: center;
  }

  .card {
    width: 40vw !important;
  }

  .grid-item.label {
    justify-self: end;
  }

  .grid-item.input {
    justify-self: start;
    min-width: 100%;
  }

  @media (max-width: 1300px) {
    .card {
      width: 70vw !important;
    }
  }

  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
      gap: 5px;
    }

    .card {
      width: auto !important;
    }

    .grid-item.label,
    .grid-item.input {
      justify-self: stretch;
      min-width: auto;
    }
  }
}