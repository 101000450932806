#supplier-dynamic{
    .space-equally-last{
        margin-top: 10px !important;
    }
    @media screen and (max-width:760px) {
        .space-equally-last{           
                width: 100% !important;
            .space-100{
                width:100% !important;
            }
        }
    }
}

.table-icon{
    svg{
        width:22px !important;
    }
}