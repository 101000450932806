.table-header-container {
    justify-content: end;
}

@media screen and (min-width:1100px) and (max-width:1300px) {

    .table-header-container-item {
        // flex-flow:column-reverse;
    }

    .reponsive_position {
        justify-content: end;
        display: flex;
        // margin-bottom: 10px;
    }

    .table-header-container .table-header-container-item-special {
        flex-flow: nowrap !important;
    }
}

@media screen and (max-width:1100px) {
    .reponsive_position {
        width: auto;
    }

    .table-header-container-item {
        flex-flow: row;
    }

    .table-header-container .table-header-container-item:nth-child(n + 3):not(:first-child) {
        // width: 100%;
        justify-content: end;
    }
}

@media screen and (max-width:991px) {
    .table-header-container {
        gap: 10px;
    }

    .p-paginator-current {
        width: 100%;
    }
}

@media screen and (max-width:768px) {
    .table-header-container .table-header-container-item .p-dropdown {
        width: 140px !important;
    }
}
