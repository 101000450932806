
.excel {
    padding: 10px;
    margin-right: 5px;
    background: #3ab73a;
    border-radius: 5px;
    font-size: 12px;
    color: antiquewhite;
    letter-spacing: 1px;
}

.excel:hover{
    cursor: pointer !important;
}
.pdf:hover{
    cursor: pointer !important;
}
.pdf {
    padding: 10px;
    margin-right: 5px;
    background: #dc4343;
    border-radius: 5px;
    font-size: 12px;
    color: antiquewhite;
    letter-spacing: 1px;
}

.icon-style-csv-pdf{
  font-size: 30px;
  margin-right: 15px;
  cursor: pointer;
}

.pdf-icon{
  color: #ff1111;
}

.csv-icon{
  color: #00853c;
}

.clear-filter-icon{
  width:32px;
  height:32px;
  cursor: pointer;
}