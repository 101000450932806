/* ==============
  Calendar
===================*/


.fc td, .fc th{
    border: $table-border-width solid $table-border-color;
}



.fc {
    .fc-toolbar {
        h2 {
            font-size: 16px;
            line-height: 30px;
            text-transform: uppercase;
        }

        @media (max-width: 767.98px) {

            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            >*>* {
                float: none;
            }

            .fc-today-button {
                display: none;
            }
        }

        .btn {
            text-transform: capitalize;
        }

    }
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        color: $gray-700;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $gray-300;
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: $card-bg;
    border-color: var(--#{$prefix}border-color);
    color: $gray-700;
    text-transform: capitalize;
    box-shadow: none;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc{
    .fc-scrollgrid table{
        border-left: 1px solid $table-border-color;
        border-top: 1px solid $table-border-color;
    }
}


.fc-event,
.fc-event-dot {
    background-color: $primary;
}

.fc-event .fc-content {
    color: $white;
}

#external-events {
    .external-event {
        text-align: left;
        padding: 8px 16px;
    }
}


.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
    .fc-content{
        color: var(--#{$prefix}light);
    }
  }
  

// RTL
[dir="rtl"]{
    .fc-header-toolbar {
        direction: ltr !important;
    }

    .fc-toolbar>*>:not(:first-child) {
        margin-left: .75em;
    }
}

.fc-toolbar{
    @media (max-width: 575.98px) {
        flex-direction: column;
    }
}


// dark
[data-bs-theme ="dark"]{
    .fc-event.bg-dark{
        background-color: $white !important;
    }
}

.fc-daygrid-event-dot {
    display: none;
  }
  .fc-event{
    background-color: rgba(255, 208, 208, 0.849);
    color:red;
  }
.calendar-dialog{
    width: 700px;
}
  .appointment-calendar{
    // padding: 5px 5px;
    .header{
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: rgb(228, 228, 228);
        border-radius: 5px;
        .job-status{
            font-size: 15px;
            font-weight: 500;
            color:green;
            background-color: rgba(208, 255, 228, 0.849) !important;
        }
        .job-view{
            font-size: 15px;
            font-weight: 500;
            color:rgb(0, 60, 128);
            cursor: pointer;
        }
    }
    .model-content{
        padding: 10px 15px;
        .data-content{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .label{
                font-size: 13px;
                font-weight: 500;
                color:rgb(100, 100, 100);
            }
            .data-value{
                font-size: 14px;
                font-weight: 500;
                color:black;
                max-width: 70%;
            }
        }
    }
  }

  @media screen and (max-width:800px){
    .calendar-dialog{
        width: 90vw !important;
    }
  }

  .dealer-based{
    background-color: rgba(208, 255, 208, 0.849) !important;
    color:green;
  }

  .customer-based{
    background-color: rgba(255, 208, 208, 0.849) !important;
    color:red;
  }

  .fc-button-primary{
    background-color: #5258ca !important;
    border: none !important;
  }
.fc-button-primary:focus,
.fc-button-primary:active {
  outline: none !important;
  box-shadow: none !important;
}

@keyframes blink {
    0% { background-color: #ffd3d3; color: #ff2f00; }
    50% { background-color: #faa1a1; color: #ff0000; }
    100% { background-color: #ffd3d3; color: #ff2f00; }
  }

@keyframes blink-new {
    0% { background-color: #d7a8ff; color: #861ce2; }
    50% { background-color: #b969ff; color: #861ce2; }
    100% { background-color: #d7a8ff; color: #861ce2; }
  }

.color-1 { 
    background-color: #ffd3d3 ; 
    color: #ff2f00 ;
    animation: blink 1s infinite; 
}

.color-5 { 
    background-color: #d7a8ff; 
    color: #861ce2; 
    animation: blink-new 1s infinite; 
}




.color-2 { background-color: #98ffab !important; color: #017e18 !important; }
.color-3 { background-color: #a8b8ff !important; color: #1c3cca !important; }
.color-4 { background-color: #ffa3d4 !important; color: #e21884 !important; }

.color-6 { background-color: #cff8f5 !important; color: #00b7ff !important; }
.color-7 { background-color: #ffdbc0 !important; color: #c45b0a !important; }
.color-8 { background-color: #caa7f8 !important; color: #5d00d6 !important; }
.color-9 { background-color: #cfffe4 !important; color: #02a749 !important; }
.color-10 { background-color: #ffc6f3 !important; color: #cc10d3 !important; }


.event-calendar{
    .p-dropdown{
        background-color: white !important;
        border-radius: 10px;
    }
    .form-label{
        font-size: 13px !important;
        margin-bottom: 0px !important;
        color: #646464 !important;
    }
    input{
        background-color: white !important;
        border-radius: 10px;
    }
}

.fc-event {
    transition: all 0.3s ease-in-out;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
//   .fc-event:hover {
//     transform: scale(1.2) !important; /* Increases the size */
//     z-index: 10 !important; /* Ensures the event appears above others */
//     white-space: normal !important; /* Allows text to wrap */
//     max-width: none !important; /* Ensures full text is visible */
//     padding: 5px !important;
//     border-radius: 5px !important;
//     display: flex;
//     flex-direction: column;
//     height: fit-content;
//   }

.nonScheduled{
    min-width: 400px;
    min-height: 200px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 15px;
    margin-top: 20px;
    .data-content{
        display: flex;
        justify-content: start;
        margin-top: 10px;
        .label{
            font-size: 13px;
            font-weight: 500;
            color:rgb(100, 100, 100);
            width: 100px;
        }
        .data-value{
            font-size: 14px;
            font-weight: 500;
            color:black;
        }

    }
}
.data-non-scheduled{
    height: 40px;
    margin: 10px;
    width: 20%;
    .label{
        font-weight: 400;
    }
}
.data-non-scheduled-header{
    height: 40px;
    margin: 10px;
    width: 20%;
    .label{
        color: black;
        font-weight: 500;
    }
}

.data-non-scheduled-index{
    height: 40px;
    margin: 10px;
    width: 40px;
}

.data-non-scheduled-header-index{
    height: 40px;
    margin: 10px;
    width: 40px;
    .label{
        color: black;
        font-weight: 500;
    }
}

.fc-timegrid-event-harness{
    .height-60{
        height: 60px !important;
    }
}