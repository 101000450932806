body[data-layout="horizontal"] {
  .pagecontent {
    padding: calc(70px + 3.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
    height: auto;
  }
}

.pagecontent {
  padding: calc(70px + 1rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
  height: auto;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.common-bottom-color {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #94949f;
}

.jobsheet-tab-type .p-accordion-content {
  padding: 0px;
}

.jobsheet_tab_header {
  background: #f0f0f0 !important;
  padding: 10px;
  margin-bottom: 10px;
}

/* Styles foe job sheet tab */


body[data-sidebar-size="sm"] .main-content {
  .breadcrumb-job-basic-info {
    width: calc(100% - 70px);
  }
}

.breadcrumb-job-basic-info {
  margin-left: -30px !important;
  position: fixed;
  width: calc(100% - 250px);
  margin-top: -12px !important;
  z-index: 1;
  background-color: #fff;
  padding: 1rem 1.50rem 0rem 1.125rem !important;
}

.breadcrumb-job-basic-info_horizondal_edit {
  margin-left: -24px !important;
  position: fixed;
  width: calc(100% - 0px);
  margin-top: -24px !important;
  z-index: 1;
  background-color: #fff;
  padding: 1rem 1.50rem 0rem 1.125rem !important;
}

.breadcrumb-job-basic-info_create_horizondal {
  margin-left: -30px !important;
  position: fixed;
  width: calc(100% - 0px);
  margin-top: -24px !important;
  z-index: 1;
  background-color: #fff;
  padding: 1rem 1.50rem 0rem 1.125rem !important;
}

.breadcrumb-job-basic-info_create_vertical {
  margin-left: -30px !important;
  position: fixed;
  width: calc(100% - 250px);
  margin-top: -24px !important;
  z-index: 1;
  background-color: #fff;
  padding: 1rem 1.50rem 0rem 1.125rem !important;
}

@media screen and (max-width: 991px) {
  body[data-layout="horizontal"] {
    .pagecontent {
      padding: calc(70px + 1rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
    }
  }

  .breadcrumb-job-basic-info_create_vertical {
    width: 100%;
  }

  body[data-sidebar-size="sm"] .breadcrumb-job-basic-info_create_vertical {
    width: 100% !important;
  }
}

.mt-mb {
  margin-top: 0.60rem !important;
  margin-bottom: 0.25rem !important;
}

.mt-mb-textarea {
  margin-top: 0.60rem !important;
}

.job-sheet-tab-content {
  height: auto;
  // margin-bottom: 20px;
  // margin-bottom: 120px;
}

.job-sheet-sub-menu {
  width: 60px !important;
  position: fixed !important;
  // z-index: 5;
  z-index: 2;
  bottom: 0;
  margin-top: 0;
  top: 185px;
  left: 250px;
}

.job-sheet-sub-menu_create_horizontal {
  width: 60px !important;
  position: fixed !important;
  z-index: 2;
  bottom: 59px;
  margin-top: 0;
  top: 188px;
  left: 0px;
}

.job-sheet-sub-menu_edit_horizontal {
  width: 60px !important;
  position: fixed !important;
  z-index: 2;
  bottom: 59px;
  margin-top: 0;
  top: 240px;
  left: 0px;
}

body[data-sidebar-size="sm"] .job-sheet-sub-menu {
  left: 70px;
}



.job-content {
  margin-left: 30px;
  overflow: hidden;
  width: 100%;
  padding-right: 5px;
}

.job-content-create-horizondal {
  margin-left: 37px;
  overflow: hidden;
  width: 100%;
}

.job-content-edit-horizondal {
  margin-left: 37px;
  overflow: hidden;
  width: 100%;
}

body[data-sidebar-size="sm"] .job-content {
  margin-left: 30px;
}

body[data-sidebar-size="sm"] .breadcrumb-job-basic-info_create_vertical {
  margin-left: 30px;
  width: calc(100% - 70px);
}

body[data-sidebar-size="sm"] .sub-footer {
  left: 130px;
  width: calc(100% - 130px) !important;
}



.job-sheet-type {
  margin-top: 100px !important;
  // padding-bottom: 100px;
}

.job-sheet-border-next-state {
  border-top: 1px solid #ececef;
}

.job-sheet-type-new {
  margin-top: 39px !important;
  // padding-bottom: 100px;
}

body[data-sidebar-size="sm"] .job-sheet-sub-menu-new {
  left: 70px;
}

.job-sheet-sub-menu-new {
  width: 60px !important;
  position: fixed !important;
  z-index: 1;
  bottom: 0;
  margin-top: 0;
  top: 125px;
  left: 250px;
}

.sub-footer {
  left: 310px;
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 1;
  background-color: #fff;
  width: calc(100% - 320px) !important;
  border-top: 1px solid #ececef;
}

.sub-footer_horizondal {
  left: 60px;
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 1;
  background-color: #fff;
  width: calc(100% - 60px) !important;
  border-top: 1px solid #ececef;
}

.custom-checkbox-label .col-lg-3 div {
  margin-top: 0px !important;
}

#cus-prime-drop .p-dropdown-label {
  padding: 0.25rem 0.75rem !important;
}

.border-bottom {
  border-bottom: 1px solid #ececef !important;
}

.border-top {
  border-top: 1px solid #ececef !important;
}

.border-left {
  border-left: 1px solid #ececef !important;
}

.border-right {
  border-right: 1px solid #ececef;
}

.border-top-no {
  border-top: none !important;
}

.job-sheet-tab {
  .nav-tabs {
    padding: 0.5rem 0.3rem;
    border: none;
    --bs-nav-tabs-link-hover-border-color: #f5f7f9;
  }

  .nav-item {
    width: 100%;
  }
}

.job-sheet-tab .nav-tabs .nav-item.active {
  background-color: #fff;

  .nav-link {
    background-color: #e8e0ff8c;

    svg {
      fill: #5f2bc5 !important;
    }
  }
}

.job-sheet-tab .nav-tabs .nav-item.error {
  background-color: #fff;

  .nav-link {
    background-color: #e8e0ff8c;

    svg {
      fill: red !important;
    }
  }
}

.job-sheet-tab .nav-tabs .nav-item {
  text-align: center;

  .nav-link {
    padding: 10px 5px !important;

    svg {
      height: 21px;
      width: 21px;
      color: #545a6d;
      // fill: rgb(84, 90, 109);
      margin-top: -3px;
      margin-right: 6px;
      margin-left: 9px;
      text-align: center;
    }

    span {
      display: none;
      padding-left: 25px;
    }
  }
}

.job-sheet-tab .nav-tabs .nav-item:hover {
  cursor: pointer;
  border-bottom: none;
  width: 100%;

  .nav-link {
    position: relative;
    transition: none;
    background-color: #e8e0ff8c;

    svg {
      fill: #5f2bc5 !important;
    }
  }
}

.job-sheet-tab {
  height: 100%
}

/* Edit Job sheet information styles */
.job-basic-info {
  padding-right: 8px;
  border-radius: 8px;
  display: flex;

  .title {
    width: 30%;
    border-bottom: 1px solid #e2e2e5;
    padding: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none !important;
    font-weight: 600;
  }

  b {
    width: 70%;
    padding: 8px 0px;
    border: 1px solid #e2e2e5 !important;
    border-left: none !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #e2e2e5 !important;
    border-bottom: 1px solid #e2e2e5 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 992px) and (min-width: 767px) {
  .job-sheet-type {
    margin-top: 150px !important;
  }

  .job-sheet-sub-menu_edit_horizontal {
    width: 50px !important;
  }

  .job-sheet-tab .nav-tabs .nav-item .nav-link {
    padding: 6px 2px !important;
  }
}

@media screen and (max-width:767px) and (min-width:575px) {
  .job-basic-info {
    padding-right: 0px;
    padding-top: 3px;
  }

  .job-sheet-type {
    margin-top: 265px !important;
  }

  .job-sheet-sub-menu,
  body[data-sidebar-size="sm"] .job-sheet-sub-menu {
    top: 336px !important;
  }
  .job-sheet-sub-menu_edit_horizontal{
    top: 340px;
    width: 50px !important;
  }
}

@media screen and (max-width:992px) {
  .job-sheet-type {
    margin-left: 0px !important;
    border-left: 1px solid #ececef;
  }

  .job-sheet-form {
    padding-left: 0px !important;

    .details {
      padding-left: 12px !important;
    }
  }

  .job-basic-info {
    padding-right: 0px;
  }

  .breadcrumb-job-basic-info {
    width: 100% !important;
  }

  .job-sheet-sub-menu, body[data-sidebar-size="sm"] .job-sheet-sub-menu{
    left: 0;
    top: 225px;
  }

  .job-sheet-sub-menu-new, body[data-sidebar-size="sm"] .job-sheet-sub-menu-new {
    left: 0;
  }
  
  .sub-footer,
  body[data-sidebar-size="sm"] .sub-footer {
    left: 0;
    width: 100% !important;
  }

  .sub-footer_horizondal,
  body[data-sidebar-size="sm"] .sub-footer_horizondal {
    left: 0;
    width: 100% !important;
  }

  .job-content,
  body[data-sidebar-size="sm"] .job-content {
    margin-left: 40px;
    padding-right: 15px;
  }

  .gridCls {
    display: grid;
    gap: 20px;
    grid-template-columns: 50% 1fr;
  }

  .parentCls2 { 
    >div {
      border-right: none !important;
    }
  }
}

@media screen and (max-width:868px) {
  .job-sheet-tab .nav-tabs .nav-item {
    .nav-link {
      svg {
        margin-left: 4px;
      }
    }
  }
}

@media screen and (max-width:575px) and (min-width:0px) {
  .job-sheet-tab {
    width: 100%;
  }

  .job-sheet-type {
    margin-top: 260px !important;
  }

  .details {
    margin-top: 0.75rem !important;
  }

  .job-sheet-sub-menu,
  body[data-sidebar-size="sm"] .job-sheet-sub-menu {
    top: 350px;
  }

  .job-sheet-sub-menu-new,
  body[data-sidebar-size="sm"] .job-sheet-sub-menu-new {
    top: 150px;
  }

  .job-sheet-type-new {
    margin-top: 58px !important;
  }
  .job-sheet-sub-menu_edit_horizontal{
    top: 350px;    
    width: 50px !important;
  }

  .pagecontent {
    padding: calc(70px + 0.25rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
    height: auto;
  }
}

@media screen and(max-width:930px)and(min-width:767px) {
  .job-basic-info {
    .title {
      width: 36%;
    }
  }
}

@media screen and (max-width:350px) {
  .job-sheet-type {
    padding-bottom: 130px;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newCourierDropDown .p-dropdown-items .p-dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: unset !important;
}

.childCls {
  display: grid;
  grid-template-columns: 250px calc(100% - 250px);

  div:first-child {
    color: #616161
  }

  div:last-child {
    font-weight: 600;
    color: #515151;
    overflow-wrap:break-word;
    padding-right: 10px;
  }

  a {
    font-weight: 500;
    color: blue;

    &:hover {
      text-decoration: underline !important;
    }
  }

  span {
    svg {
      width: 17px !important;
      justify-content: end !important;
      margin: 0px 5px !important
    }
  }
}

.parentCls {
  display: grid;
  grid-template-columns: 50% calc(50% - 20px);
  column-gap: 20px;

  >div:nth-child(odd) {
    border-right: 1px solid #eee;
  }
}

.parentCls3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;

  .text-light {
    font-size: 13px;
    font-weight: 500 !important;
    color: black !important;
  }
}

.parentCls2 {
  column-gap: 20px;

  >div {
    border-right: 1px solid #eee;
  }
}

@media screen and (max-width: 820px) {
  .parentCls {
    grid-template-columns: 100%;

    >div {
      border-right: none !important;
    }
    .childCls:nth-child(odd) {
      order: 1;
    }
    .childCls:nth-child(even) {
      order: 2;
    }
  }

  // .childCls {
  //   display: grid;
  //   grid-template-columns: 191px 50%;

  // }

  .parentCls2 {
    column-gap: 20px;
  }
}

@media screen and (max-width:800px) {
  .parentCls3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .childCls {
    display: block;

    div:first-child {
      max-width: 100%;
    }

    div:last-child {
      margin-bottom: 10px;
    }
  }

  .parentCls3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
  }
}


@media screen and (max-width: 567px) {
  .childCls {
    display: grid;
    gap: 20px;
    grid-template-columns: 50% 1fr;
  }
  .childCls > div{
    overflow-wrap: anywhere !important;
  }

  .childClsNew {
    grid-template-columns: 50% 1fr !important;
    gap: 10px;
  }

  .service-details {
    display: grid !important;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
  }

  .color-blue {
    height: fit-content;
    width: max-content;
  }

  .job-sheet-tab-content {
    margin-right: 10px !important;
  }
}

@media screen and (min-width: 992px) and (max-width:1200px) {
  .p-float-label label {
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.1;
  }
}

.transistive-state {
  border-top: 1px solid #ececef;
  padding-top: 10px;
}

.view-jobsheet-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.estimate_amount {
  display: grid;
  grid-template-columns: 50% calc(50% - 20px);
  column-gap: 20px;
}

.estimate_amount div:last-child {
  font-weight: 600;
  color: #515151;
}

.hours_to_creation{
  color: green;
  font-weight: 600;
}