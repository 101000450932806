#attendance {
  #column-action {
    .display-data-view {
      color: black !important;
      font-weight: 400;
    }

    .fa-ellipsis-v {
      display: none;
    }
  }

  .brand-details {
    td {
      padding: 5px 0px;
      min-width: 400px;
    }
  }

  tr {
    border-bottom: 1px solid var(--bs-border-color);
    height: 43px;
  }

  .p-column-filter-menu {
    margin: 0px !important;

    button {
      margin-left: 90px;
    }
  }
}

@media screen and (max-width: 1100px) {
  #attendance .table-header-container .table-header-container-item:nth-child(n + 3):not(:first-child) {
    width: auto;
    justify-content: start;
  }
}

.parent-container {
  position: relative;
  width: 100%;
}

.custom-calendar {
  z-index: 1001;
  position: absolute;
  top: 1.094px;
  right: 0;
  width: 20%;
  transform-origin: center top;
  left: auto;
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active {
  color: #5156BE !important;
  background-color: rgba(81, 86, 190, 0.1) !important;
}

.calendar-container {
  justify-content: space-between !important;

  .table-header-border {
    border-right: 1px solid black;
    margin-right: 10px;
  }
}

.main-content-calendar {
  margin: 0 auto;

}

#calendar{
  button{
    padding: 3px 5px !important;
    svg{
      width: 20px;
    }
  }
}