
#permission{
  ul {
    margin-bottom: 0px !important;
  }
  .text-danger{
    position: absolute;
  }
  .btn-primary{
    padding:3px 5px; 
    svg{
      margin:0px !important;
      padding:2px;
    }
  }
  .margin-left-20{
   margin-left: 30px;
 
  }
 
 .bold-text-parent{
   margin-bottom: -5px;
   color:rgb(96, 78, 184);
 }
 .p-accordion-tab{
   width: 25% !important;
   .p-accordion-content{
     height: 300px !important;
     overflow-y: auto;
     scrollbar-width: thin;
     scrollbar-color: rgba(211, 211, 211, 0.5) transparent;
     scroll-behavior: smooth;
   }
 }
 @media screen and (max-width:1300px) {
   .p-accordion-tab{
     width: 30% !important;
     .p-accordion-content{
       height: 300px !important;
       overflow-y: auto;
     }
   }
 }
 @media screen and (max-width:850px) {
   .p-accordion-tab{
     width: 50% !important;
     .p-accordion-content{
       height: 300px !important;
       overflow-y: auto;
     }
   }
 }
 @media screen and (max-width:500px) {
   .p-accordion-tab{
     width: 100% !important;
     .p-accordion-content{
       height: 300px !important;
       overflow-y: auto;
     }
   }
 }



 .p-accordion-header{
  background-color:rgba(252, 252, 255, 0.51) !important;
  .p-accordion-header-link{
    background-color:rgba(245, 245, 255, 0.508) !important;
  }
  .p-accordion-header-text{
    color:#5156BE !important;
  }
 }
 .p-steps{
  ul{
    display: flex !important;
    flex-wrap: wrap !important;
    .p-steps-number{
      font-size: 14px !important;
      font-weight: bold;
    }
  }
 }

 @media screen and (max-width:1600px) {
  .p-steps{
    ul{
      display: flex !important;
      flex-wrap: wrap !important;
      li{
        a{
          .p-steps-title{
            display: none;
          }
        }
      }
      .p-steps-item:before{
        margin-top: 0rem !important;
      }
    }
   }
}

}
