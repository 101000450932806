#customize-table {
  position: fixed;
  top: 20%;
  right: 20px;
  z-index: 10000;
  max-width: 400px;
  margin-left: 10px;
  border: 1px solid #e9e9ef;
  box-shadow: 1px 1px 1px #e9e9ef;
  background: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .p-checkbox{
    margin-bottom: 8px;
  }


  .flex {
    display: flex;
  }

  h6 {
    margin-top: 10px;
  }

  .field-list-container {
    max-height: 30vh;
    overflow-y: auto;
  }

  .no-match-found {
    color: blue;
  }


  .span-total-column-display {
    background: #dfeffc;
    color: black;
    padding: 0px 5px;
    border-radius: 3px;
  }

  .btn-container{
    display:flex;    
    border-top: 1px solid #e9e9ef;
    padding-top: 10px;
  }

}