.CheckedIn {
    .btn {
        background-color: #00cfa8 !important;
    }
}

.CheckedOut {
    .btn {
        background-color: #f4586e !important;
    }

}

.attendance {
    color: #fff !important;
    border-radius: 20px !important;
    padding: 0.2rem 0.5rem 0.2rem 0.6rem !important;
    width: 115px;
    text-align: center;

    svg {
        margin-top: 2px;
        width: 17px;
        height: 17px;
    }
}

.attendance:hover {
    color: #fff !important;
}