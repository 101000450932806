#bulk-action-status {
  font-weight: 700;
  display: flex;
  justify-content: end;

  .status {
    padding: 1px 4px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #5156BE;
    color: white;
  }
}

@media screen and (max-width:598px) {
  #bulk-action-status{
    justify-content: center;
}
}