#column-action {
    white-space: nowrap;

    svg {
        margin: 0px 5px;
        cursor: pointer;
        font-size: 14px;
    }

    .btn {
        margin: 0px 5px;
    }

    .columnActionContainer {
        display: flex;
        align-items: center;
        width: 250px;
        height: 35px;
        text-transform: capitalize;
    }

    .display-data-view {
        font-size: 15px;
        color: #2c5cc5;
        font-weight: 800;
    }

    .display-action-data:hover {
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
    }

    .min-size {
        // max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 10px;
    }

    .options-btn-icon {
        padding: 5px 10px;
        cursor: pointer;
    }

    .options-btn-icon:hover,
    .options-btn-icon:active,
    .options-btn-icon:focus,
    .options-btn-icon.active {
        background: #dbdada;
        border-radius: 4px;
        cursor: pointer;
    }

    .option-icons {
        display: flex;
        align-items: center;
        font-size: large;
        padding: 5px 10px;
        font-weight: 200;
        cursor: pointer;
        color: black;
  
    }

    .special{
        font-weight: 700;
    }

    .option-icons:hover,
    .option-icons:focus {
        background: #dbdada;
        border-radius: 4px;
        font-weight: 700;
        cursor: pointer;
    }

    .max-size {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

  
.text-approve{
    color:green
}

.text-reject{
    color:red
}
    // .edit{
    //     color: green;
    // }
    // .view{
    //     color: blue;
    // }
    // .delete{
    //     color: red;
    // }
}