.payment-sucess-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
    .payment-dialog-container{
        background-color: rgba(253, 253, 253, 0.904);
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        height: 300px;
        width:600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:20px;
    }
    .img-container{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100px;
            height: 100px;

        }
    }
}