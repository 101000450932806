.userpage {
    padding: calc(70px + 1.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
}
body[data-layout="horizontal"] {
    .userpage {
        padding: calc(70px + 4.5rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
    }
}
.p-fieldset {
    border: 1px solid #e5e7eb !important;
    background: #ffffff !important;
    color: #4b5563 !important;
    border-radius: 6px !important;
    margin: 10px 10px 15px;
    padding: inherit;
}

.p-fieldset .p-fieldset-legend {
    padding: 10px;
    border: 1px solid #e5e7eb !important;
    color: #374151 !important;
    background: #f0f0f0 !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    width: unset;
    float: none;
    font-size: 14px;
}

@media screen and (max-width: 991px) {
    body[data-layout="horizontal"] {
        .userpage {
            padding: calc(70px + 1rem) calc(1.5rem * 0.75) 60px calc(1.5rem * 0.75);
        }
    }
}

