.p-float-label {

    .p-disabled {
        background-color: #eeeeee ;
    }

    .p-autocomplete {
        width: 100%;
    }

    label {
        font-size: 13px;
    }

    .form-control {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .p-inputtextarea {
        height: auto !important;
    }

    .p-input-filled .p-dropdown {
        height: 37px;
    }

    /*     .p-dropdown {
        height: 37px;
    } */
    .p-dropdown .p-inputtext {
        width: 100%;
        height: 35px;
        //new style added
        // overflow: hidden;
        // text-overflow: ellipsis;
        // max-width: 205px;
    }

    .p-inputtext {
        width: 100%;
        height: 37px;
    }

    .p-inputtext {
        background-color: #f8f9fa;
        color: #313533 !important;
        font-size: 14px !important;

    }

    input:focus~label,
    input.p-filled~label,
    .p-inputwrapper-focus~label,
    .p-inputwrapper-filled~label,
    textarea:focus~label,
    textarea.p-filled~label {
        font-size: 12px;
        color: rgba(49, 53, 51, 0.5);
        font-weight: 500;
        position: absolute;
        background-color: #f8f9fa;
        margin-bottom: 0;
        padding: 0 5px;
        top: -0.1rem;
        left: 10px;
        margin-top: -0.2rem;
    }

    .p-input-filled .p-inputtext,
    .p-dropdown .p-dropdown-trigger,
    .p-dropdown,
    .p-input-filled .p-dropdown,
    .p-dropdown .p-dropdown-label,
    .p-input-filled .p-dropdown:not(.p-disabled):hover,
    .p-input-filled .p-dropdown {
        background-color: #f8f9fa;
    }

    .p-inputtext:enabled:focus {
        border: 2px solid rgb(81, 86, 190) !important;
        outline: rgba(39, 45, 158, 0.7) !important;
        box-shadow: none !important;
        background-color: #f8f9fa !important;
        color: #313533 !important;
    }

    /* Dropdown field styles */
    .p-focus {
        border: 2px solid rgb(81, 86, 190) !important;
        outline: rgba(39, 45, 158, 0.7) !important;
        box-shadow: none !important;
        color: #313533 !important;
    }

    /* date picker */
    .p-datepicker-trigger {
        background-color: #f8f9fa !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-left: 1px solid white !important;

        svg {
            color: #604eb8;
        }
    }

    .p-button:focus {
        box-shadow: none;
    }

    .p-button.p-button-icon-only {
        padding: 0px !important;
    }
}


#textOverflow {
    .p-dropdown .p-inputtext {
        width: 100%;
        height: 35px;
        //new style added
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 205px;
    }

}

// .float-disabled{
//     cursor: no-drop !important;
//     background-color: #eeeeee !important;
// }