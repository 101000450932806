// #view{
   
// .view{
//     width:50%;
//     input , textarea{
//         width:100% !important;
//         .childCls{
//             margin-top: 20px;
//         }
//     }
   
// }
// @media screen and (max-width:1200px) {
//     .view{
//         width:60% !important;
//         input , textarea{
//             width:100% !important;
//         }
//     }
// }
// @media screen and (max-width:1000px) {
//     .view{
//         width:70% !important;
//         input , textarea{
//             width:90% !important;
//         }
//     }
// }
// @media screen and (max-width:800px) {
//     .view{
//         width:80% !important;
//         input , textarea{
//             width:100% !important;
//         }
//     }
// }
// @media screen and (max-width:700px) {
//     .view{
//         width:100% !important;
//         input , textarea{
//             width:100% !important;
//         }
//     }
// }
// }
#profile{
    .form-control{
        background-color: white !important;
        // border: 1.5px solid #d9d9dd !important;
    }
    .form-control:disabled{
        background-color: #e9e9e9 !important;
    }
    .phoneField{
        position: relative;
    }
    button.btn-primary{
        padding:5px 8px !important;
        svg{
            width: 17px !important;
            height: 14px !important;
            margin-right: 5px !important;
        }
    }
    @media screen and (max-width:900px) {
        .custom-margin{
            margin-bottom: 20px;
        }
    }
    }
    #profilebody{

        .image-container{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            img{
                width:200px;
                height:200px;
                border-radius: 50%;
            }
        }
    }
.image-viewer{
  
    display: flex;
    justify-content: center;
    div{
        position: relative;
    img{
        height:200px;
        width:200px;
        border-radius: 50%;
    }
    .icon-container{
        background-color: #5156BE;
        color:white;
        position: absolute;
        bottom: 10px;
        left: 160px;
        padding: 3px;
        border-radius: 3px;
        svg{

            width:20px;
        }
    }
    }
}